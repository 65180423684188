import styled from '@emotion/styled';
import type { ColumnFiltersState } from '@tanstack/react-table';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useModalActionsWithTracking } from '@jane/business-admin/hooks';
import { GlobalSpecialsTableProvider } from '@jane/business-admin/providers';
import type { AbbreviatedGlobalSpecialsV2 } from '@jane/business-admin/types';
import { ModalNames, businessPaths } from '@jane/business-admin/util';
import { ConditionalWrapper } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { BulkEditModal } from '../specials/bulk_editing/BulkEditModal';
import { GlobalSpecialsTable } from './GlobalSpecialsTable';

const Wrapper = styled.div({
  display: 'flex',
  ...spacing({ py: 40 }),
});

export const GlobalSpecialsLanding = () => {
  const navigate = useNavigate();
  const [bulkSelectedSpecials, setBulkSelectedSpecials] = useState<number[]>(
    []
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const openModal = ({ id }: { id?: number | string }) => {
    if (id) {
      navigate(businessPaths.globalSpecialDetail(String(id)));
    } else {
      navigate(businessPaths.globalSpecialDetail('create'));
    }
  };

  const {
    modalOpen: bulkEditModalOpen,
    openModal: setBulkEditModalOpen,
    closeModal: setBulkEditModalClose,
  } = useModalActionsWithTracking(ModalNames.BulkEditGlobalSpecials);

  return (
    <Wrapper>
      <ConditionalWrapper
        condition={bulkEditModalOpen}
        wrapper={(child) => (
          <BulkEditModal
            bulkSelectedSpecials={bulkSelectedSpecials}
            onClose={setBulkEditModalClose}
            variant="global"
          >
            {child}
          </BulkEditModal>
        )}
      >
        <GlobalSpecialsTableProvider
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          bulkEditModalOpen={bulkEditModalOpen}
          bulkSelectedSpecials={bulkSelectedSpecials}
          setBulkSelectedSpecials={setBulkSelectedSpecials}
        >
          <GlobalSpecialsTable
            onEditSpecial={(special: Partial<AbbreviatedGlobalSpecialsV2>) => {
              if (bulkEditModalOpen) return;
              openModal({ id: special?.id });
            }}
            openModal={openModal}
            setBulkEditModalOpen={setBulkEditModalOpen}
          />
        </GlobalSpecialsTableProvider>
      </ConditionalWrapper>
      <Outlet />
    </Wrapper>
  );
};
