import get from 'lodash/get';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useInView } from 'react-intersection-observer';
import { useParams } from 'react-router-dom';

import {
  useFetchBrands,
  useFetchGlobalBrands,
} from '@jane/business-admin/data-access';
import type { Brand } from '@jane/business-admin/types';
import { ModalNames, blankArrayOfLength } from '@jane/business-admin/util';
import {
  Button,
  Flex,
  Modal,
  SearchField,
  Skeleton,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { ConditionType } from '../../form';
import {
  Condition,
  ConditionByModal,
} from '../generic_components/ConditionByModal';
import { LoadingCondition } from '../generic_components/LoadingCondition';
import { BrandCheckboxRow } from './BrandCheckboxRow';

const BrandPickerLoading = () => {
  const length10Array = blankArrayOfLength(10);

  return (
    <>
      {length10Array.map((i) => (
        <Skeleton
          animate
          height={72}
          style={{
            boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.1)',
          }}
          key={i}
        >
          <Flex py={12} px={24} height={72} alignItems="center">
            <Skeleton.Bone borderRadius="circular" width={46} height={46} />
            <Skeleton.Bone width="90%" ml={24} />
          </Flex>
        </Skeleton>
      ))}
    </>
  );
};

export const BrandConditions = ({
  fieldPrefix,
  isGlobalSpecial,
  type,
}: {
  fieldPrefix: string;
  isGlobalSpecial?: boolean;
  type: ConditionType.Brands | ConditionType.ExcludeBrands;
}) => {
  const { id: storeId = '' } = useParams<'id'>();
  const [query, setQuery] = useState('');
  const [brandConditionsModalOpen, setBrandConditionsModalOpen] =
    useState(false);

  const { setValue, watch } = useFormContext();

  const rulesWatch = watch(`${fieldPrefix}.rules`);
  const includesOrExcludes =
    type === ConditionType.Brands ? 'includes' : 'excludes';

  const [initialSelectedBrands, setInitialSelectedBrands] = useState<string[]>(
    () => get(rulesWatch, `${includesOrExcludes}.0.brands`, [])
  );

  const [selectedBrands, setSelectedBrands] = useState<Brand['name'][]>(
    initialSelectedBrands
  );

  const wrapperRef = useRef(null);

  const useGetBrandData = () => {
    const {
      data: allBrands,
      isFetching: allBrandsFetching,
      isFetchingNextPage: allBrandsFetchingNextPage,
      fetchNextPage: allBrandsFetchNextPage,
    } = useFetchBrands(
      storeId,
      { name: query, order: 'asc' },
      !isGlobalSpecial
    );

    const {
      data: globalBrands,
      isFetching: globalBrandsFetching,
      isFetchingNextPage: globalBrandsFetchingNextPage,
      fetchNextPage: globalBrandsFetchNextPage,
    } = useFetchGlobalBrands(
      { name: query, order: 'asc' },
      isGlobalSpecial || false
    );

    return {
      data: !isGlobalSpecial ? allBrands : globalBrands,
      isFetching: !isGlobalSpecial ? allBrandsFetching : globalBrandsFetching,
      isFetchingNextPage: !isGlobalSpecial
        ? allBrandsFetchingNextPage
        : globalBrandsFetchingNextPage,
      fetchNextPage: !isGlobalSpecial
        ? allBrandsFetchNextPage
        : globalBrandsFetchNextPage,
    };
  };

  const {
    data: brandData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetBrandData();

  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
  });

  const rules =
    rulesWatch && rulesWatch[includesOrExcludes]
      ? rulesWatch[includesOrExcludes]
      : null;

  const brandConditions = initialSelectedBrands?.map((brand) => ({
    value: brand,
    label: brand,
  }));

  const onSubmit = (data: Condition[] | string[]) => {
    const isConditionArray = (value: unknown): value is Condition[] =>
      Condition.array().safeParse(value).success;

    const values = isConditionArray(data)
      ? data.map(({ label }) => `${label}`)
      : data;

    const firstCondition = rules?.[0] ?? {};

    setValue(
      `${fieldPrefix}.rules`,
      {
        ...rulesWatch,
        [includesOrExcludes]: [{ ...firstCondition, brands: values }],
      },
      { shouldDirty: true }
    );

    setQuery('');
    setSelectedBrands(values);
    setInitialSelectedBrands(values);
    setBrandConditionsModalOpen(false);
  };

  const select = (brand: Brand) => {
    // We are de-selecting a brand, so we filter it out
    const newSelectedBrands = selectedBrands.filter(
      (selectedBrand) => selectedBrand !== brand.name
    );

    // We are selecting a brand since our newly selected brand was not found in the filter operation and our lengths remain the same
    if (selectedBrands.length === newSelectedBrands.length) {
      newSelectedBrands.push(brand.name);
    }

    setSelectedBrands(newSelectedBrands);
  };

  const isSelected = (brand: Brand) => selectedBrands.includes(brand.name);

  return (
    <>
      {!brandConditions ? (
        <LoadingCondition />
      ) : (
        <ConditionByModal
          onOpenModal={() => setBrandConditionsModalOpen(true)}
          type={type}
          conditions={brandConditions}
          conditionsLoading={isFetching}
          onConditionsUpdate={onSubmit}
        />
      )}
      <ConfirmWrapperWithTracking
        open={brandConditionsModalOpen}
        onCloseDiscardChanges={() => {
          setSelectedBrands(initialSelectedBrands);
        }}
        setOpen={() => {
          setQuery('');
          setBrandConditionsModalOpen(false);
        }}
        hasChanges={selectedBrands.length !== brandConditions?.length}
        modalName={ModalNames.BrandConditions}
        overlayClose={true}
      >
        <>
          <Modal.Header
            title="Select brands"
            actions={
              <Button
                variant="primary"
                label="Save"
                onClick={() => onSubmit(selectedBrands)}
                disabled={selectedBrands.length === 0}
              />
            }
          />
          <Modal.Content ref={wrapperRef}>
            <SearchField
              borderRadius="sm"
              label="Search Brands"
              name="brand-search-field"
              onChange={setQuery}
              placeholder="Search for a brand"
              mb={24}
            />
            {isFetching ? (
              <BrandPickerLoading />
            ) : (
              <>
                {brandData?.map((brand, i) => {
                  const isFirst = i === 0;
                  const isLast = i === brandData.length - 1;
                  const needsDivider = !isFirst;

                  return (
                    <BrandCheckboxRow
                      key={brand.id}
                      brand={brand}
                      onClick={() => select(brand)}
                      selected={isSelected(brand)}
                      divider={needsDivider}
                      ref={isLast ? ref : undefined}
                    />
                  );
                })}
              </>
            )}
          </Modal.Content>
        </>
      </ConfirmWrapperWithTracking>
    </>
  );
};
