import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import type {
  GlobalSpecialV2,
  GlobalSpecialsIndexResponse,
} from '@jane/business-admin/types';
import {
  tGlobalSpecialDetailResponse,
  tGlobalSpecialsIndexResponse,
} from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';
import type { Id, StackingOptions, StoreSpecial } from '@jane/shared/models';

// ----------------- INDEX ----------------- \\

type fetchSpecialsProps = {
  order?: string;
  page?: string;
  per_page?: string;
  query?: string;
  sort?: string;
  special_type?: string;
  status?: string;
  store_id?: string;
};

const fetchGlobalSpecials = async (
  params: fetchSpecialsProps
): Promise<GlobalSpecialsIndexResponse> => {
  const urlParams = new URLSearchParams();
  params.status && urlParams.append('status', params.status);
  params.query && urlParams.append('query', params.query);
  params.sort && urlParams.append('sort', params.sort);
  params.order && urlParams.append('order', params.order);
  params.special_type && urlParams.append('special_type', params.special_type);
  params.page && urlParams.append('page', params.page);
  params.per_page && urlParams.append('per_page', params.per_page);

  const url = `/business/specials?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<GlobalSpecialsIndexResponse>(url),
    tGlobalSpecialsIndexResponse,
    url
  );

  return data;
};

export const useFetchGlobalSpecials = (params: Partial<fetchSpecialsProps>) =>
  useInfiniteQuery<GlobalSpecialsIndexResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchGlobalSpecials({
        ...params,
        page: pageParam.toString(),
      });

      return {
        ...data,
        pageParam,
      };
    },
    queryKey: ['global_specials', params],
    getNextPageParam: (lastPage) => {
      const numPages = Math.ceil(
        lastPage?.meta.total / lastPage?.meta.per_page
      );
      const hasNextPage = lastPage?.meta.page < numPages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
  });

// ----------------- SHOW ----------------- \\
// todo: test - hasn't been used yet (if anything is wrong, it's probably types lol)

const fetchGlobalSpecial = async (
  specialId: string | number
): Promise<GlobalSpecialV2> => {
  const url = `/business/specials/${specialId}`;
  const data = await fetchWithDecode(
    janeApiV2.get<{ special: GlobalSpecialV2 }>(url),
    tGlobalSpecialDetailResponse,
    url
  );
  return data.special;
};

export const useFetchGlobalSpecial = (
  specialId: string | number,
  disabled?: boolean
) =>
  useQuery({
    queryKey: ['global_specials', specialId],
    queryFn: () => fetchGlobalSpecial(specialId),
    enabled: !disabled,
    // Don't keep cached data, causes form defaultValues to be outdated
    cacheTime: 0,
  });

// ----------------- PATCH ----------------- \\
// todo: test - hasn't been used yet (if anything is wrong, it's probably types lol)

export type UpdateGlobalSpecialRequest = Omit<GlobalSpecialV2, 'photo'> & {
  is_duplicate: boolean;
};

const updateGlobalSpecial = (
  specialId: Id,
  // TODO: update these params as more cards are added to global specials modal
  special: UpdateGlobalSpecialRequest
): Promise<CreateSpecialResponse> =>
  janeApiV2.patch(`/business/specials/${specialId}`, {
    ...special,
  });

export const useUpdateGlobalSpecial = (id: Id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: UpdateGlobalSpecialRequest) =>
      updateGlobalSpecial(id, params),
    mutationKey: ['global_specials', id],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['global_specials']);
    },
  });
};

// ----------------- CREATE ----------------- \\
// todo: test - hasn't been used yet (if anything is wrong, it's probably types lol)

interface CreateSpecialResponse {
  special: StoreSpecial;
}
const createGlobalSpecial = (
  special: UpdateGlobalSpecialRequest
): Promise<CreateSpecialResponse> =>
  janeApiV2.post(`/business/specials`, {
    ...special,
  });

export const useCreateGlobalSpecial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params: UpdateGlobalSpecialRequest) =>
      createGlobalSpecial(params),
    mutationKey: ['global_specials'],
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['global_specials']);
    },
  });
};

// ----------------- CHECK PROMO CODE ----------------- \\
// todo: test - hasn't been used yet (if anything is wrong, it's probably types lol)

type ValidateGlobalPromoCodeParams = {
  promoCode: string;
};

const validateGlobalPromoCode = (promoCode: string): Promise<any> => {
  const urlParams = new URLSearchParams();
  promoCode && urlParams.append('promo_code', promoCode);

  return janeApiV2.get(
    `/business/specials/promo_codes/check?${urlParams.toString()}`
  );
};

export const useValidateGlobalPromoCode = () =>
  useMutation({
    mutationKey: ['validate_global_promo_code'],
    mutationFn: ({ promoCode }: ValidateGlobalPromoCodeParams) =>
      validateGlobalPromoCode(promoCode),
  });

// ----------------- TOGGLE ----------------- \\

const toggleGlobalSpecialEnabled = async (
  specialId: string,
  enabled: boolean,
  storeId?: number
): Promise<null> => {
  const url = `/business/specials/${specialId}/toggle`;
  const request: { enabled: boolean; store_id?: number } = {
    enabled,
  };
  if (storeId) {
    request['store_id'] = storeId;
  }

  return await janeApiV2.patch<null>(url, request);
};

export const useToggleGlobalSpecialEnabled = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      enabled,
      specialId,
      storeId,
    }: {
      enabled: boolean;
      specialId: string;
      storeId?: number;
    }) => toggleGlobalSpecialEnabled(specialId, enabled, storeId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['global_specials']);
      // Can update global specials from the store-specific page, so clear that cache
      queryClient.resetQueries(['current_specials_legacy']);
      queryClient.resetQueries(['store_specials']);
    },
  });
};

// ----------------- ARCHIVE ----------------- \\

const archiveGlobalSpecial = async (specialId: string): Promise<null> => {
  const url = `/business/specials/${specialId}/archive`;

  return await janeApiV2.post<null>(url, {});
};

export const useArchiveGlobalSpecial = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ specialId }: { specialId: string }) =>
      archiveGlobalSpecial(specialId),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['global_specials']);
    },
  });
};

// ----------------- BULK UPDATE ----------------- \\
export interface BulkUpdateGlobalSpecialParams {
  archive?: boolean;
  attributes?: { enabled?: boolean; stacking_setting?: StackingOptions };
  special_ids: Id[];
}
export interface BulkUpdateGlobalResponse {
  success: string[];
}

const bulkUpdateGlobalSpecial = (
  params: BulkUpdateGlobalSpecialParams
): Promise<BulkUpdateGlobalResponse> =>
  janeApiV2.post('/business/specials/bulk', params);

export const useBulkUpdateGlobalSpecial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BulkUpdateGlobalSpecialParams) =>
      bulkUpdateGlobalSpecial(params),
    onError: (error) => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.resetQueries(['global_specials']);
    },
  });
};
