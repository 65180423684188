import { useIsMutating } from '@tanstack/react-query';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';

import type {
  SpecialRulesV2,
  StoreSpecialV2,
} from '@jane/business-admin/types';
import { buildRules } from '@jane/business-admin/util';
import { RULE_OPTIONS } from '@jane/shared/util';

import {
  convertBulkConditionsToRules,
  convertBundleConditionsToRules,
} from './conditions/utils/legacyConditionsHelpers';

export const MAX_MESSAGE_LENGTH = 300;
export const FORM_FIELD_DEFAULT_MARGIN = 24;

export enum SPECIAL_TYPES {
  BulkPricing = 'bulk_pricing',
  Bundle = 'bundle',
  CartTotal = 'cart_total',
  Product = 'product',
  QualifiedGroup = 'qualified_group',
}

export const SHOW_PROMO_CODE_SPECIAL_TYPES = [
  SPECIAL_TYPES.BulkPricing,
  SPECIAL_TYPES.CartTotal,
  SPECIAL_TYPES.Product,
];

export enum ConditionType {
  Brands = 'brands',
  Categories = 'categories',
  ExcludeBrands = 'exclude brands',
  ExcludeCategories = 'exclude categories',
  ExcludeProducts = 'exclude products',
  Lineage = 'lineage',
  Price = 'price',
  Products = 'products',
  Weight = 'weight',
  WeightAndPrice = 'weight and price',
}

export const getBundleDisplay = (
  isCreateMode: boolean,
  special?: StoreSpecialV2,
  isGlobal?: boolean
) => {
  if (isCreateMode) {
    return {
      threshold_number_of_items_in_cart: 1, // Minimum products requirement
      max_number_of_discounted_products: 1, // Max products discounted
      settings: {
        allow_discounts_on_required_products: false, // Allow qualifying products to be discounted
      },
      max_applications_per_cart: 1, // Max uses per order
    };
  }

  const conditions = isGlobal ? nestConditions(special) : special?.conditions;

  if (special?.special_type === 'bundle') {
    return {
      threshold_number_of_items_in_cart:
        conditions?.bundle?.independent?.threshold_number_of_items_in_cart,
      settings: conditions?.bundle?.settings,
      max_number_of_discounted_products:
        conditions?.bundle?.dependent?.max_number_of_discounted_products,
      max_applications_per_cart: conditions?.bundle?.max_applications_per_cart,
    };
  }
  return {};
};

export const nestConditions = (special?: StoreSpecialV2) => {
  if (special?.special_type) {
    return { [special.special_type]: { ...special?.conditions } };
  }

  return special?.conditions;
};

export const getRules = (
  isCreateMode: boolean,
  special?: StoreSpecialV2,
  isGlobal?: boolean
) => {
  if (isCreateMode) {
    return {
      required_rules: {
        apply_to: RULE_OPTIONS[0].value,
        rules: {},
      },
      default_rules: {
        rules: {},
        apply_to: RULE_OPTIONS[0].value,
      },
      discounted_rules: {
        apply_to: RULE_OPTIONS[0].value,
        rules: {},
      },
    };
  }

  const conditions = isGlobal ? nestConditions(special) : special?.conditions;

  if (special?.special_type === 'bundle') {
    // If special is bundle, convert bundle conditions to rules so our Conditions UI can handle it
    return convertBundleConditionsToRules(conditions?.bundle);
  }
  if (special?.special_type === 'bulk_pricing') {
    return {
      default_rules: convertBulkConditionsToRules(conditions?.bulk_pricing),
    };
  } else {
    return {
      default_rules: {
        rules: special?.rules,
        apply_to: getApplyToSelection(special?.rules),
      },
    };
  }
};

/**
 * Used when rendering the form, will return which "Apply To" radio selection should be set based on the special rules.
 * @param rules SpecialRulesV2
 * @returns One of the RULE_OPTIONS values
 */
export const getApplyToSelection = (rules?: SpecialRulesV2 | null) => {
  const defaultValue = RULE_OPTIONS[0].value;

  if (!rules) {
    return defaultValue;
  }

  const excludes = rules['excludes'] || [];
  const includes = rules['includes'] || [];
  const hasExclusions = !isEmpty(excludes);
  const hasInclusions = !isEmpty(includes);
  const firstExclusion = excludes[0] || [];
  const firstInclusion = includes[0] || [];

  const hasAdvancedInclusions =
    !isEmpty(firstInclusion['kinds']) ||
    !isEmpty(firstInclusion['brands']) ||
    !isEmpty(firstInclusion['lineages']);
  const hasAdvancedExclusions =
    !isEmpty(firstExclusion['kinds']) || !isEmpty(firstExclusion['brands']);

  // Has product inclusions, should be INDIVIDUAL
  if (hasInclusions && !isEmpty(firstInclusion['product_ids'])) {
    return RULE_OPTIONS[1].value;
    // Has category or brands or lineage inclusions, or category or brands exclusions, should be CONDITIONS
  } else if (
    (hasInclusions && hasAdvancedInclusions) ||
    (hasExclusions && hasAdvancedExclusions)
  ) {
    return RULE_OPTIONS[2].value;
  } else {
    return defaultValue;
  }
};

/**
 * Used when saving the form, will return a rules object based on which "Apply To" selection was made.
 * @param rules SpecialRulesV2
 * @param applyTo One of the RULE_OPTIONS values
 * @returns The rules that should be used when saving
 */
export const parseRulesFromApplyTo = (
  rules: SpecialRulesV2,
  applyTo: 'ALL' | 'INDIVIDUAL' | 'CONDITIONS',
  specialType?: string
) => {
  if (applyTo === 'CONDITIONS') {
    return omit(rules, 'includes.0.product_ids');
  }

  if (applyTo === 'ALL') {
    const includes = pick(
      get(rules, 'includes.0'),
      specialType !== 'bulk_pricing' ? ['weights'] : ['weights_and_prices']
    );
    const excludes = pick(get(rules, 'excludes.0'), ['product_ids']);

    return {
      product_threshold: rules.product_threshold,
      ...buildRules({
        excludeProduct: excludes.product_ids,
        includeWeight: includes.weights || includes.weights_and_prices,
      }),
    };
  } else if (applyTo === 'INDIVIDUAL') {
    const weights =
      specialType !== 'bulk_pricing' ? 'weights' : 'weights_and_prices';
    const includes = pick(get(rules, 'includes.0'), [weights, 'product_ids']);

    return {
      product_threshold: rules.product_threshold,
      ...buildRules({
        includeProduct: includes.product_ids,
        includeWeight: includes.weights || includes.weights_and_prices,
      }),
    };
  }
  return rules;
};

export const useTriggerDuplicateValidation = (
  mutationKey: any,
  specialIsLoading: boolean,
  isDuplicateMode: boolean,
  trigger: any
) => {
  const isMutating = useIsMutating({
    mutationKey,
  });
  const [hasDuplicationErrors, setHasDuplicationErrors] = useState(false);
  // Trigger form validation when duplicating special
  useEffect(() => {
    if (isDuplicateMode && !specialIsLoading && !isMutating) {
      // Slight delay to make sure form is loaded and rendered
      setTimeout(async () => {
        const initialErrors = await trigger(undefined, { shouldFocus: true });
        setHasDuplicationErrors(!initialErrors);
      }, 500);
    }
  }, [specialIsLoading, isDuplicateMode, isMutating]);

  return hasDuplicationErrors;
};
