import styled from '@emotion/styled';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Flex,
  ThumbsUpIcon,
  Typography,
  effects,
} from '@jane/shared/reefer';
import { shadows, spacing } from '@jane/shared/reefer-emotion';

import { FeedbackModal } from './FeedbackModal';

const BUTTON_HEIGHT = '48px';

const ButtonWrapper = styled(Flex)<{ hidden?: boolean }>(({ hidden }) => ({
  position: 'fixed',
  bottom: '40px',
  right: '0px',
  zIndex: 'var(--layers-visible)',
  ...(hidden && { display: 'none' }),
}));

const FeedbackIconButton = styled.button(({ theme }) => ({
  height: BUTTON_HEIGHT,
  backgroundColor: theme.colors.grays.ultralight,
  border: 'none',
  borderTopLeftRadius: theme.borderRadius.sm,
  borderBottomLeftRadius: theme.borderRadius.sm,
  borderRight: `1px solid ${theme.colors.grays.light}`,
  boxShadow: shadows.drop,
  '&:hover': {
    background: effects.darken(theme.colors.grays.ultralight, 0.1),
    cursor: 'pointer',
  },
}));

const ChevronButton = styled.button<{ isCollapsed?: boolean }>(
  ({ theme, isCollapsed }) => ({
    ...spacing({ pr: 12, pl: 12 }),
    height: BUTTON_HEIGHT,
    boxShadow: shadows.drop,
    backgroundColor: theme.colors.grays.ultralight,
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      background: effects.darken(theme.colors.grays.ultralight, 0.1),
      cursor: 'pointer',
    },

    ...(isCollapsed && {
      borderTopLeftRadius: theme.borderRadius.sm,
      borderBottomLeftRadius: theme.borderRadius.sm,
    }),
  })
);

export const FeedbackButton = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [body] = useState(() => document.querySelector('body'));

  if (!body) return null;

  return createPortal(
    <>
      <ButtonWrapper hidden={feedbackModalOpen}>
        <FeedbackIconButton
          hidden={isCollapsed}
          type="button"
          onClick={() => setFeedbackModalOpen(true)}
        >
          <Flex justifyContent="space-between" alignItems="center" px={8}>
            <ThumbsUpIcon size="sm" mr={8} />
            <Typography variant="body-bold">Give feedback</Typography>
          </Flex>
        </FeedbackIconButton>
        <ChevronButton
          type="button"
          isCollapsed={isCollapsed}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {isCollapsed ? (
            <ChevronLeftIcon
              size="sm"
              altText="Open feedback button"
              data-testid="open-feedback-button"
            />
          ) : (
            <ChevronRightIcon
              size="sm"
              altText="Close feedback button"
              data-testid="close-feedback-button"
            />
          )}
        </ChevronButton>
      </ButtonWrapper>
      <FeedbackModal
        shouldRedirect={false}
        feedbackModalOpen={feedbackModalOpen}
        setFeedbackModalOpen={setFeedbackModalOpen}
      />
    </>,
    body
  );
};
