import styled from '@emotion/styled';

import { LAYOUT_MAX_WIDTH } from '@jane/shared-ecomm/components';
import type { Spacing } from '@jane/shared/reefer';
import { Box, Grid } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const StyledGridContainer = styled(Grid.Container)({
  height: 'auto',
});

export const HeaderRow = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.grays.white,
  position: 'sticky',
  top: 0,
  zIndex: 'var(--layers-header)',
}));

export const AlertsRow = styled(Box)({
  zIndex: 'var(--layers-visible)',
  maxWidth: LAYOUT_MAX_WIDTH,
  position: 'sticky',
  top: 48,
  ...spacing({ px: 24, mx: 'auto', mb: -8, pt: 8 }),
  [mediaQueries.desktop('sm', 'min')]: {
    top: 80,
    ...spacing({ px: 56 }),
  },
});

export const ContentContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: 24,
  backgroundColor: theme.colors.grays.ultralight,
  [mediaQueries.desktop('sm', 'min')]: {
    backgroundColor: theme.colors.grays.white,
    gridTemplateColumns: '55% 1fr',
  },
}));

export const CheckoutStep = styled(Box)<{ mt?: Spacing }>(
  ({ theme, mt = 0 }) => ({
    backgroundColor: theme.colors.grays.white,
    borderRadius: 0,
    ...spacing({ p: 24, mb: 16, mt }),
    [mediaQueries.desktop('sm', 'min')]: {
      ...spacing({ p: 40, mb: 24 }),
      borderRadius: theme.borderRadius.sm,
      border: `1px solid ${theme.colors.grays.light}`,
    },
    hr: {
      color: theme.colors.grays.mid,
      ...spacing({ my: 16 }),
      [mediaQueries.desktop('sm', 'min')]: {
        ...spacing({ mx: -40, my: 40 }),
      },
    },
  })
);
