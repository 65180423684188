import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import type {
  FilterResponse,
  SearchResponse,
} from '@jane/business-admin/types';
import { tFilterResponse, tSearchResponse } from '@jane/business-admin/types';
import { fetchWithDecode } from '@jane/business-admin/util';
import { janeApiV2 } from '@jane/shared/data-access';

import { STORES_URL } from './stores';

const fetchProductSearchFilters = async (
  storeId: string,
  kind?: string[]
): Promise<FilterResponse> => {
  const urlParams = new URLSearchParams();
  kind && kind.length > 0 && urlParams.append('kind', kind.join(','));

  const url = `${STORES_URL}/${storeId}/product_filters?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<FilterResponse>(url),
    tFilterResponse,
    url
  );

  return data;
};

export const useProductSearchFilters = (
  storeId: string,
  kind?: string[],
  enabled?: boolean
) => {
  return useQuery<FilterResponse>({
    queryFn: () => fetchProductSearchFilters(storeId, kind),
    queryKey: ['searchFilters', storeId, kind],
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled,
  });
};

const fetchProductSearch = async (
  storeId: string,
  brand?: string,
  kind?: string,
  lineage?: string,
  name?: string,
  subtype?: string,
  weight?: string,
  includeHidden?: boolean,
  onlyShowInStock = true,
  page?: number
): Promise<SearchResponse> => {
  const urlParams = new URLSearchParams();
  brand && urlParams.append('brand', brand);
  kind && urlParams.append('kind', kind);
  lineage && urlParams.append('lineage', lineage);
  name && urlParams.append('name', name);
  subtype && urlParams.append('subtype', subtype);
  weight && urlParams.append('weight', weight);
  includeHidden && urlParams.append('include_hidden', includeHidden.toString());
  onlyShowInStock &&
    urlParams.append('only_show_in_stock', onlyShowInStock.toString());
  page && urlParams.append('page', page.toString());

  const url = `${STORES_URL}/${storeId}/product_search?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<SearchResponse>(url),
    tSearchResponse,
    url
  );

  return data;
};

export const useProductSearch = ({
  brand,
  enabled = true,
  includeHidden,
  kind,
  lineage,
  name,
  onlyShowInStock = true,
  storeId,
  subtype,
  weight,
}: {
  brand?: string;
  enabled?: boolean;
  includeHidden?: boolean;
  kind?: string;
  lineage?: string;
  name?: string;
  onlyShowInStock?: boolean;
  storeId: string;
  subtype?: string;
  weight?: string;
}) => {
  return useInfiniteQuery<SearchResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchProductSearch(
        storeId,
        brand,
        kind,
        lineage,
        name,
        subtype,
        weight,
        includeHidden,
        onlyShowInStock,
        pageParam
      );
      return {
        ...data,
        pageParam,
      };
    },
    queryKey: [
      'searchStoreProducts',
      storeId,
      brand,
      kind,
      lineage,
      name,
      subtype,
      weight,
      includeHidden,
      onlyShowInStock,
    ],
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled,
  });
};

const fetchGlobalProductSearchFilters = async (
  kind?: string[]
): Promise<FilterResponse> => {
  const urlParams = new URLSearchParams();
  kind && kind.length > 0 && urlParams.append('filters[kind]', kind.join(','));

  const url = `/business/specials/products/search_filters?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<FilterResponse>(url),
    tFilterResponse,
    url
  );

  return data;
};

export const useGlobalProductSearchFilters = (
  kind?: string[],
  enabled?: boolean
) => {
  return useQuery<FilterResponse>({
    queryFn: () => fetchGlobalProductSearchFilters(kind),
    queryKey: ['globalSearchFilters', kind],
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled,
  });
};

const fetchGlobalProductSearch = async (
  rowId?: string,
  brand?: string,
  kind?: string,
  lineage?: string,
  name?: string,
  subtype?: string,
  weight?: string,
  includeHidden?: boolean,
  onlyShowInStock = true,
  page?: number
): Promise<SearchResponse> => {
  const urlParams = new URLSearchParams();
  rowId && urlParams.append('row_id', rowId);
  brand && urlParams.append('brand', brand);
  kind && urlParams.append('kind', kind);
  lineage && urlParams.append('lineage', lineage);
  name && urlParams.append('name', name);
  subtype && urlParams.append('subtype', subtype);
  weight && urlParams.append('weight', weight);
  includeHidden && urlParams.append('include_hidden', includeHidden.toString());
  onlyShowInStock &&
    urlParams.append('only_show_in_stock', onlyShowInStock.toString());
  page && urlParams.append('page', page.toString());

  const url = `/business/specials/products/search?${urlParams.toString()}`;
  const data = await fetchWithDecode(
    janeApiV2.get<SearchResponse>(url),
    tSearchResponse,
    url
  );

  return data;
};

export const useGlobalProductSearch = ({
  brand,
  enabled = false,
  includeHidden,
  kind,
  lineage,
  name,
  onlyShowInStock = true,
  rowId,
  subtype,
  weight,
}: {
  brand?: string;
  enabled?: boolean;
  includeHidden?: boolean;
  kind?: string;
  lineage?: string;
  name?: string;
  onlyShowInStock?: boolean;
  rowId?: string;
  subtype?: string;
  weight?: string;
}) => {
  return useInfiniteQuery<SearchResponse>({
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchGlobalProductSearch(
        rowId,
        brand,
        kind,
        lineage,
        name,
        subtype,
        weight,
        includeHidden,
        onlyShowInStock,
        pageParam
      );
      return {
        ...data,
        pageParam,
      };
    },
    queryKey: [
      'searchGlobalProducts',
      rowId,
      brand,
      kind,
      lineage,
      name,
      subtype,
      weight,
      includeHidden,
      onlyShowInStock,
    ],
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage?.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    staleTime: Infinity,
    useErrorBoundary: true,
    enabled,
  });
};
