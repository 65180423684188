import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import type { GlobalSpecialV2 } from '@jane/business-admin/types';
import { BODY_LINE_HEIGHT } from '@jane/business-admin/util';
import {
  Flex,
  Form,
  Modal,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { DiscountTypeAndDiscountAmountField } from './DiscountTypeAndDiscountAmountField';
import { SpecialTypeCriteria } from './SpecialTypeCriteria';
import {
  FORM_FIELD_DEFAULT_MARGIN,
  MAX_MESSAGE_LENGTH,
  SPECIAL_TYPES,
} from './form';

export const LoadingDetailsCard = () => {
  return (
    <Flex flexDirection="column">
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_status`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="20%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_name_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={48} width="100%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_select`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_special_type_criteria`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="30%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        {Array(2)
          .fill(null)
          .map((_, index) => {
            return (
              <Skeleton animate width="100%" key={`loading_2${index}`}>
                <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={2} width="60%" />
                <Skeleton.Bone height={BODY_LINE_HEIGHT} width="80%" />
              </Skeleton>
            );
          })}
      </Flex>
      <Flex mb={20} width="100%">
        <Skeleton animate width="100%" key={`loading_description_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>

      <Modal.ContentDivider />

      <Flex mt={20} mb={40} gap={24} width="100%">
        <Skeleton animate width="100%" key={`loading_reservation_modes`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={BODY_LINE_HEIGHT} width="40%" />
        </Skeleton>
      </Flex>
      <Flex mb={40} width="100%">
        <Skeleton animate width="100%" key={`loading_terms_input`}>
          <Skeleton.Bone height={BODY_LINE_HEIGHT} mb={12} width="20%" />
          <Skeleton.Bone height={76} width="100%" />
        </Skeleton>
      </Flex>
    </Flex>
  );
};

export const DetailsCard = ({
  special,
  isCreateMode,
  isEditable,
  isLoading,
}: {
  isCreateMode: boolean;
  isEditable?: boolean;
  isLoading: boolean;
  special?: GlobalSpecialV2 | null;
}) => {
  const { watch } = useFormContext();

  const { pathname } = useLocation();
  const pathIncludesDuplicate = pathname.includes('duplicate');

  const { description = '', special_type = '' } = {
    ...special,
  };

  // TODO: maybe update to useWatch()?
  const descriptionWatch = watch('description');
  const specialTypeWatch = watch('special_type');

  const messageLength = useMemo(
    () =>
      descriptionWatch?.length || (!isCreateMode && description?.length) || 0,
    [descriptionWatch, description]
  );

  const specialTypeOptions = () => {
    const commonValues = [
      {
        value: SPECIAL_TYPES.BulkPricing,
        label: 'Bulk Pricing',
      },
      {
        value: SPECIAL_TYPES.Bundle,
        label: 'Buy X Get Y',
      },
      {
        value: SPECIAL_TYPES.Product,
        label: 'Product',
      },
      {
        value: SPECIAL_TYPES.CartTotal,
        label: 'Cart Total',
      },
      {
        value: SPECIAL_TYPES.QualifiedGroup,
        label: 'Qualified Group',
      },
    ];
    const selectValue = { value: 'select', label: 'Select' };

    if (isCreateMode) {
      return [selectValue].concat(commonValues);
    }

    return commonValues;
  };

  return (
    <>
      <Typography variant="header-bold" mb={FORM_FIELD_DEFAULT_MARGIN}>
        Details
      </Typography>
      {isLoading ? (
        <LoadingDetailsCard />
      ) : (
        <>
          <Typography color="grays-black" variant="body-bold">
            Name
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            <Form.TextField
              label="Name"
              name="name"
              required
              labelHidden
              maxLength={40}
              disabled={!isEditable}
            />
          </Flex>
          <Typography color="grays-black" variant="body-bold">
            Special type
          </Typography>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            {isCreateMode || pathIncludesDuplicate ? (
              <>
                <Form.SelectField
                  required
                  label="Special type"
                  labelHidden
                  name="special_type"
                  options={specialTypeOptions()}
                  width={'100%'}
                  validate={(value: string) => {
                    return value !== 'select'
                      ? true
                      : 'Must choose a valid special type';
                  }}
                />

                {/* Needed to get the spacing right */}
                <Flex width="100%">&nbsp;</Flex>
              </>
            ) : (
              <Flex width="50%" flexDirection="column">
                <Typography color="grays-black" variant="body" mt={4} as="p">
                  {
                    specialTypeOptions().find(
                      (type) =>
                        type.value === (specialTypeWatch || special_type)
                    )?.label
                  }
                </Typography>
              </Flex>
            )}
          </Flex>
          <Flex gap={24}>
            <SpecialTypeCriteria isCreateMode={isCreateMode} />
          </Flex>
          <Flex mb={FORM_FIELD_DEFAULT_MARGIN} gap={24}>
            <DiscountTypeAndDiscountAmountField />
          </Flex>
          <Flex width="100%" flexDirection="column">
            <Flex gap={4}>
              <Typography color="grays-black" variant="body-bold">
                Description
              </Typography>
              <Typography color="grays-mid" variant="body">
                ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
              </Typography>
            </Flex>
            <Form.TextAreaField
              label="Description"
              labelHidden
              name="description"
              width="100%"
              maxLength={MAX_MESSAGE_LENGTH}
              rows={4}
              data-testid="special-description"
              disabled={!isEditable}
            />
          </Flex>

          <Modal.ContentDivider />

          <Flex
            width="100%"
            justifyContent="space-between"
            flexDirection="column"
          >
            <Typography color="grays-black" variant="body-bold">
              Apply to reservation
            </Typography>
            <Flex mb={FORM_FIELD_DEFAULT_MARGIN}>
              <Flex gap={12} mt={12}>
                <Form.CheckboxField
                  name="reservation_modes.delivery"
                  onChange={() => null}
                  label="Delivery"
                  disabled={!isEditable}
                />
                <Form.CheckboxField
                  name="reservation_modes.pickup"
                  onChange={() => null}
                  label="Pickup"
                  disabled={!isEditable}
                />
              </Flex>
            </Flex>

            <Flex
              width="100%"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Typography color="grays-black" variant="body-bold">
                Stacking
              </Typography>
              <Flex mt={12} flexDirection="row">
                <Form.RadioFieldGroup
                  row
                  name="stacking_setting"
                  disabled={!isEditable}
                  options={[
                    {
                      id: 'stacking-setting-yes',
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      id: 'stacking-setting-no',
                      label: 'No',
                      value: 'no',
                    },
                    {
                      id: 'stacking-setting-combinable',
                      label: 'Only with other stackable specials',
                      value: 'combinable',
                    },
                  ]}
                />
              </Flex>
            </Flex>

            <Typography color="grays-black" variant="body-bold">
              Terms and conditions
            </Typography>
            <Flex flexDirection="column" width={'100%'}>
              <Form.TextAreaField
                label="Terms and conditions"
                labelHidden
                name="terms"
                width="100%"
                rows={4}
                disabled={!isEditable}
              />
            </Flex>
          </Flex>
        </>
      )}
    </>
  );
};
