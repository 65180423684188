import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import {
  strongholdQueryKeys,
  useStrongholdCustomer,
} from '@jane/shared-ecomm/data-access';
import { useCheckout } from '@jane/shared-ecomm/providers';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import type {
  StrongholdCustomer,
  StrongholdPaymentSource,
} from '@jane/shared/models';
import { useMfa } from '@jane/shared/providers';
import { Button } from '@jane/shared/reefer';
import { trackError } from '@jane/shared/util';

import { CheckoutErrorModal } from '../../CheckoutErrorModal';
import { useHasNewPhone } from '../../util/hooks/useHasNewPhone';
import { useCheckoutPayments } from '../CheckoutPaymentsProvider';
import { loadStrongholdWidget } from './loadStrongholdWidget';

export const StrongholdButton = () => {
  const [hasError, setHasError] = useState(false);
  const { hasNewPhone, newPhone } = useHasNewPhone();
  const { launch, requiresMfa } = useMfa();

  const {
    customer: { authenticated, firstName, lastName, phone },
    store,
  } = useCheckout();

  const queryClient = useQueryClient();
  const { data: strongholdCustomerData, isFetched } = useStrongholdCustomer({
    firstName,
    lastName,
    phone,
    storeId: store.id,
  });

  const { updatePrepayments } = useCheckoutPayments();

  const closeWidget = () => {
    document.body.style.overflow = 'unset';
  };

  const handleSuccess = (paymentSource: StrongholdPaymentSource) => {
    queryClient.setQueryData(
      strongholdQueryKeys.strongholdCustomer({
        storeId: store.id,
        firstName,
        lastName,
        phone,
      }),
      (cachedData: unknown) => {
        const data = cachedData as StrongholdCustomer;
        return {
          ...data,
          customer: {
            ...data.customer,
            payment_sources: [...data.customer.payment_sources, paymentSource],
          },
        };
      }
    );

    track({
      event: EventNames.AddedStrongholdPaymentSource,
    });
    updatePrepayments({ strongholdSourceId: paymentSource.id });
    closeWidget();
  };

  const handleClick = () => {
    if (authenticated && (requiresMfa() || hasNewPhone)) {
      launch({ onSuccess: loadWidget, newPhone });
    } else {
      loadWidget();
    }
  };

  const handleError = (error: string) => {
    setHasError(true);

    trackError(new Error('Unable to add Stronghold payment source'), {
      strongholdError: error,
    });
  };

  const handleClose = () => {
    updatePrepayments({ strongholdSourceId: '' });
    closeWidget();
  };

  const loadWidget = () => {
    loadStrongholdWidget({
      strongholdCustomer: strongholdCustomerData as StrongholdCustomer,
      strongholdIntegration: store.stronghold_integration as any,
      onError: (err) => handleError(err),
      onSuccess: handleSuccess,
      onClose: handleClose,
    });
  };

  useEffect(() => {
    return () => {
      closeWidget();
    };
  }, []);

  useEffect(() => {
    if (
      strongholdCustomerData &&
      !!strongholdCustomerData?.customer.payment_sources.length
    ) {
      const defaultPayment =
        strongholdCustomerData.customer.payment_sources.find(
          (source) => source.active
        ) || strongholdCustomerData.customer.payment_sources[0];

      updatePrepayments({ strongholdSourceId: defaultPayment.id });
    }
  }, [strongholdCustomerData]);

  if (strongholdCustomerData?.customer.payment_sources.length) return null;

  return (
    <>
      <Button
        mb={24}
        full
        label="Set up Stronghold"
        onClick={handleClick}
        loading={!isFetched}
      />

      <CheckoutErrorModal
        handleClose={() => setHasError(false)}
        open={hasError}
      />
    </>
  );
};
