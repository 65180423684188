import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';

import { Flex } from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';

import { useGetPage } from '../../hooks/useGetPage';
import { MenuCommsBanner } from './MenuCommsBanner';
import { MenuReviewBanner } from './MenuReviewBanner';

const StyledFlex = styled(Flex)({
  '&:empty': {
    padding: 0,
  },
});

export const MenuBanners = ({ tabs }: { tabs?: MenuCategoryPath[] }) => {
  const params = useParams();
  const page = useGetPage();
  const firstTab = tabs && tabs[0];

  const showBanners =
    page === 'menu' ||
    page === firstTab ||
    (page === 'category' && params['category'] === firstTab);

  if (!showBanners) return null;

  return (
    <StyledFlex gap={24} flexDirection="column" pb={24}>
      <MenuCommsBanner />
      <MenuReviewBanner />
    </StyledFlex>
  );
};
