import get from 'lodash/get';

import type { SpecialRulesV2 } from '@jane/business-admin/types';
import { buildBundleConditions, buildRules } from '@jane/business-admin/util';
import type {
  BulkPricingConditions,
  BundleProductConditions,
  PriceId,
} from '@jane/shared/models';

import { getApplyToSelection } from '../../form';

export const convertBulkConditionsToRules = (
  bulkConditions?: BulkPricingConditions
) => {
  if (!bulkConditions) {
    return;
  }

  const rules = buildRules({
    excludeProduct: bulkConditions.excluded_product_ids,
    includeBrand: bulkConditions.brands,
    includeCategory: bulkConditions.kinds,
    includeLineage: bulkConditions.lineage ? [bulkConditions.lineage] : false,
    includeProduct: bulkConditions.included_product_ids,
    includeWeightAndPrice: bulkConditions.bulk_weights,
  });

  return {
    apply_to: getApplyToSelection(rules),
    rules,
  };
};

export const convertRulesToBulkConditions = ({
  rules,
  max_applications_per_cart,
  target_weight,
}: {
  max_applications_per_cart: number;
  rules: SpecialRulesV2;
  target_weight: PriceId;
}) => {
  return {
    excluded_product_ids: get(rules, 'excludes.0.product_ids'),
    brands: get(rules, 'includes.0.brands'),
    bulk_weights:
      get(rules, 'includes.0.weights') ||
      get(rules, 'includes.0.weights_and_prices'),
    included_product_ids: get(rules, 'includes.0.product_ids'),
    kinds: get(rules, 'includes.0.kinds'),
    max_applications_per_cart,
    lineage: get(rules, 'includes.0.lineages', [])[0],
    target_weight,
  };
};

export const convertBundleConditionsToRules = (
  bundleConditions?: BundleProductConditions
) => {
  if (!bundleConditions) {
    return;
  }

  const requiredRules = buildRules({
    excludeProduct: bundleConditions.independent?.excluded_product_ids,
    includeBrand: bundleConditions.independent?.brands,
    includeCategory: bundleConditions.independent?.kinds,
    includeLineage: bundleConditions.independent?.lineage
      ? [bundleConditions.independent.lineage]
      : false,
    includeProduct: bundleConditions.independent?.included_product_ids,
    includePrices: bundleConditions.independent?.product_threshold,
    includeWeight: bundleConditions.independent?.weights,
  });

  const discountedRules = buildRules({
    excludeProduct: bundleConditions.dependent?.excluded_product_ids,
    includeBrand: bundleConditions.dependent?.brands,
    includeCategory: bundleConditions.dependent?.kinds,
    includeLineage: bundleConditions.dependent?.lineage
      ? [bundleConditions.dependent.lineage]
      : false,
    includeProduct: bundleConditions.dependent?.included_product_ids,
    includePrices: bundleConditions.dependent?.product_threshold,
    includeWeight: bundleConditions.dependent?.weights,
  });

  return {
    required_rules: {
      apply_to: getApplyToSelection(requiredRules),
      rules: requiredRules,
    },
    discounted_rules: {
      apply_to: getApplyToSelection(discountedRules),
      rules: discountedRules,
    },
  };
};

export const convertRulesToBundleConditions = ({
  discountedRules,
  max_number_of_discounted_products,
  requiredRules,
  threshold_number_of_items_in_cart,
  settings,
  max_applications_per_cart,
  type,
}: {
  discountedRules: SpecialRulesV2;
  max_applications_per_cart: number;
  max_number_of_discounted_products: number;
  requiredRules: SpecialRulesV2;
  settings: any;
  threshold_number_of_items_in_cart: number;
  type?: 'bundle' | 'bulk_pricing';
}) => {
  const dependentConditions = {
    brandConditions: get(discountedRules, 'includes.0.brands'),
    includedProductConditions: get(discountedRules, 'includes.0.product_ids'),
    excludedProductConditions: get(discountedRules, 'excludes.0.product_ids'),
    kindConditions: get(discountedRules, 'includes.0.kinds'),
    lineageConditions: get(discountedRules, 'includes.0.lineages'),
    priceConditions: get(discountedRules, 'product_threshold'),
    weightConditions: get(discountedRules, 'includes.0.weights'),
  };
  const independentConditions = {
    brandConditions: get(requiredRules, 'includes.0.brands'),
    includedProductConditions: get(requiredRules, 'includes.0.product_ids'),
    excludedProductConditions: get(requiredRules, 'excludes.0.product_ids'),
    kindConditions: get(requiredRules, 'includes.0.kinds'),
    lineageConditions: get(requiredRules, 'includes.0.lineages'),
    priceConditions: get(requiredRules, 'product_threshold'),
    weightConditions: get(requiredRules, 'includes.0.weights'),
  };

  return {
    dependent: {
      ...buildBundleConditions(dependentConditions),
      max_number_of_discounted_products,
    },
    independent: {
      ...buildBundleConditions(independentConditions),
      threshold_number_of_items_in_cart,
    },
    settings,
    max_applications_per_cart,
  };
};
