import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import pluralise from 'pluralise';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  type UpdateSpecialParams,
  useBulkUpdateSpecial,
  useCreateSpecial,
  useFetchStoreSpecial,
  useStoreSettings,
  useToggleGlobalSpecialEnabled,
  useUpdateSpecial,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import {
  SpecialsModalProvider,
  StoreDetailsContext,
} from '@jane/business-admin/providers';
import {
  type SpecialRulesV2,
  SpecialStatus,
  type StoreSpecialV2,
} from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  businessPaths,
  parseValidationErrors,
  shouldShowArchiveButton,
  track,
} from '@jane/business-admin/util';
import type { ValueOf } from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { SpecialSchedule, SpecialType } from '@jane/shared/models';
import {
  Button,
  Card,
  ErrorIcon,
  Flex,
  Form,
  FormValidationError,
  Modal,
  Typography,
  useForm,
  useToast,
} from '@jane/shared/reefer';
import { type Photo } from '@jane/shared/types';

import { ConfirmWrapperWithTracking } from '../../../../ConfirmWrapperWithTracking';
import { SwitchWithBorder } from '../../../../SwitchWithBorder';
import { ImagesCard } from '../../../../shared/specials/modal/ImagesCard';
import { PromoCodeCard } from '../../../../shared/specials/modal/PromoCodeCard';
import { ConditionsCard } from '../../../../shared/specials/modal/conditions/ConditionsCard';
import {
  convertRulesToBulkConditions,
  convertRulesToBundleConditions,
} from '../../../../shared/specials/modal/conditions/utils/legacyConditionsHelpers';
import {
  SHOW_PROMO_CODE_SPECIAL_TYPES,
  getBundleDisplay,
  getRules,
  parseRulesFromApplyTo,
  useTriggerDuplicateValidation,
} from '../../../../shared/specials/modal/form';
import { ScheduleCard } from '../../../../shared/specials/modal/schedule/ScheduleCard';
import { convert12HourTimeTo24HourTime } from '../../../../shared/specials/modal/schedule/ScheduleTimeValidation';
import {
  DEFAULT_SPECIAL_SCHEDULE,
  formatSchedule,
  parseSchedule,
} from '../../../../shared/specials/modal/schedule/scheduleHelpers';
import { ArchiveWithConfirm } from '../../../../specials/ArchiveWithConfirm';
import { DetailsCard } from './DetailsCard';

dayjs.extend(utc);
dayjs.extend(timezone);

const FORM_ERROR_NAME = 'special-edit-error';

type FormRules = {
  apply_to: 'ALL' | 'INDIVIDUAL' | 'CONDITIONS';
  rules: SpecialRulesV2;
};
type SpecialFormData = StoreSpecialV2 & {
  display: {
    bundle: {
      max_applications_per_cart?: number | null;
      max_number_of_discounted_products?: number | null;
      settings?: any;
      threshold_number_of_items_in_cart?: number | null;
    };
    default_rules: FormRules | undefined;
    discounted_rules: FormRules | undefined;
    promo_code_enabled: boolean;
    required_rules: FormRules | undefined;
  };
  end_date: string | null | undefined;
  has_schedule_overrides: boolean;
  is_duplicate: boolean;
  name: string;
  photo: Photo[];
  schedule: SpecialSchedule;
  start_date: string | null | undefined;
  use_store_close_time: boolean;
};

const Mode = {
  create: 'create',
  update: 'update',
  duplicate: 'duplicate',
} as const;

type Mode = ValueOf<typeof Mode>;

const modeToGenericErrorStringMap: Record<Mode, string> = {
  [Mode.create]: 'creating',
  [Mode.update]: 'updating',
  [Mode.duplicate]: 'duplicating',
};

const modeToSuccessMessageSuffixMap: Record<Mode, string> = {
  [Mode.create]: 'created',
  [Mode.duplicate]: 'created',
  [Mode.update]: 'updated',
};

const modeToActionMap: Record<Mode, 'create' | 'duplicate' | 'update'> = {
  [Mode.create]: 'create',
  [Mode.duplicate]: 'duplicate',
  [Mode.update]: 'update',
};

const modeToModalHeaderTitleMap: Record<Mode, string> = {
  [Mode.create]: 'Create Special',
  [Mode.duplicate]: 'Duplicate Special',
  [Mode.update]: 'Edit Special',
};

export const SpecialsModal = () => {
  const { storeId, storeName } = useContext(StoreDetailsContext);
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [headerError, setHeaderError] = useState<string>();
  const { special_id: specialId = '' } = useParams<'special_id'>();
  const previousStatus = localStorage.getItem('currentSpecialStatus');
  const specialIdIsCreate = specialId === 'create';

  const { pathname } = useLocation();
  const pathIncludesDuplicate = pathname.includes('duplicate');

  const hasGlobalSpecialsAccess = useFlag(FLAGS.scGlobalSpecialsBeta);

  const mode = pathIncludesDuplicate
    ? Mode.duplicate
    : specialIdIsCreate
    ? Mode.create
    : Mode.update;

  const genericErrorMessage = `Error ${modeToGenericErrorStringMap[mode]} special. Please try again.`;

  const navigate = useNavigate();
  const toast = useToast();

  const { data: special, isFetching: specialLoading } = useFetchStoreSpecial(
    storeId,
    specialId,
    mode === Mode.create
  );

  const isArchived = special?.archived || false;
  // Navigate and show error if there is no special when loading is finished
  useEffect(() => {
    if (mode !== Mode.create && !specialLoading && !special) {
      toast.add({
        label: 'Special could not be found.',
        variant: 'error',
      });
      navigate(
        businessPaths.storeSpecials(
          storeId,
          (previousStatus as SpecialStatus) || SpecialStatus.live
        )
      );
      setIsOpen(false);
    }
  }, [specialLoading, special]);

  const {
    mutateAsync: createSpecial,
    isLoading: isCreating,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useCreateSpecial(storeId);
  const {
    mutateAsync: updateSpecial,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateSpecial(storeId, specialId);
  const {
    mutateAsync: toggleGlobalSpecial,
    isLoading: isTogglingGlobal,
    isSuccess: toggleGlobalSuccess,
    isError: toggleGlobalError,
  } = useToggleGlobalSpecialEnabled();
  const {
    mutateAsync: archiveSpecial,
    isLoading: archiveSpecialLoading,
    isSuccess: archiveSpecialSuccess,
  } = useBulkUpdateSpecial(storeId);

  useEffect(() => {
    if (specialId) setIsOpen(true);
  }, [specialId]);

  const userCanEditSpecials = useHasPermissions([Permission.EditSpecials]);

  const catchSubmitErrors = useCatchErrorsWithManager(genericErrorMessage);

  const parsedSchedule = parseSchedule(special, mode === Mode.create);

  const isCreateMode = mode === Mode.create;
  const fetchedSpecialValues = {
    name: special?.title || '',
    special_type: special?.special_type || '',
    conditions: special?.conditions || {},
    discount_dollar_amount: special?.discount_dollar_amount,
    discount_percent: special?.discount_percent,
    discount_target_price: special?.discount_target_price,
    discount_type: special?.discount_type || '',
    enabled: !!special?.enabled,
    description: special?.description || '',
    reservation_modes: special?.reservation_modes,
    stacking_setting: special?.stacking_setting,
    terms: special?.terms || '',
    photo:
      special?.photo && special.photo['urls']['original']
        ? [special.photo['urls']['original']]
        : undefined,
    promo_code: special?.promo_code,
    multiple_use_promo_code:
      special?.promo_code && special?.multiple_use_promo_code,
    promo_code_max_number_of_uses:
      special?.promo_code_max_number_of_uses || undefined,
    display: {
      promo_code_enabled: !!special?.promo_code,
      ...getRules(isCreateMode, special),
      bundle: getBundleDisplay(isCreateMode, special),
    },
    ...parsedSchedule,
    is_duplicate: false,
  };

  const formMethods = useForm({
    defaultValues: {
      name: isCreateMode ? '' : special?.title || '',
      special_type: isCreateMode ? '' : special?.special_type || '',
      conditions: isCreateMode
        ? {
            cart_total: {
              threshold: 0,
            },
            bulk_pricing: {
              target_weight: 'ounce',
              max_applications_per_cart: 1,
            },
            qualified_group: {
              type: 'senior',
              required_age: 65,
            },
            bundle: {
              settings: {
                allow_discounts_on_required_products: false, // Allow qualifying products to be discounted
              },
              dependent: {
                max_number_of_discounted_products: 1, // Max products discounted
              },
              independent: {
                threshold_number_of_items_in_cart: 1, // Minimum products requirement
              },
              max_applications_per_cart: 1, // Max uses per order
            },
          }
        : special?.conditions || {},
      discount_type: isCreateMode ? 'percent' : special?.discount_type || '',
      discount_dollar_amount: isCreateMode
        ? 0
        : special?.discount_dollar_amount || 0,
      discount_percent: isCreateMode ? 0 : special?.discount_percent || 0,
      discount_target_price: isCreateMode
        ? 0
        : special?.discount_target_price || 0,
      enabled: isCreateMode ? false : !!special?.enabled,
      description: isCreateMode ? '' : special?.description || '',
      reservation_modes: isCreateMode
        ? {
            delivery: true,
            pickup: true,
          }
        : {
            delivery: !!special?.reservation_modes?.delivery,
            pickup: !!special?.reservation_modes?.pickup,
          },
      terms: isCreateMode ? '' : special?.terms || '',
      display: {
        promo_code_enabled: isCreateMode ? false : !!special?.promo_code,
        ...getRules(mode === Mode.create, special),
        bundle: getBundleDisplay(isCreateMode, special),
      },
      photo: isCreateMode
        ? undefined
        : special?.photo && special.photo['urls']['original']
        ? [special.photo['urls']['original']]
        : undefined,
      promo_code: isCreateMode ? '' : special?.promo_code,
      multiple_use_promo_code: isCreateMode
        ? false
        : !!(special?.promo_code && special?.multiple_use_promo_code),
      promo_code_max_number_of_uses: isCreateMode
        ? undefined
        : special?.promo_code_max_number_of_uses || undefined,
      stacking_setting: isCreateMode ? 'yes' : special?.stacking_setting,
      ...parsedSchedule,
      is_duplicate: false,
    },
    values: isCreateMode ? undefined : fetchedSpecialValues,
  });

  const isMutating =
    isCreating || isUpdating || isTogglingGlobal || archiveSpecialLoading;

  useMutationStatusToasts({
    isMutating: isCreating || isUpdating || isTogglingGlobal,
    isSuccess: isCreateSuccess || isUpdateSuccess || toggleGlobalSuccess,
    isError: isCreateError || isUpdateError || toggleGlobalError,
    successMessage: `Special successfully ${modeToSuccessMessageSuffixMap[mode]}`,
    errorMessage: genericErrorMessage,
  });

  const {
    formState: { isDirty, dirtyFields },
    setError,
    watch,
    trigger,
  } = formMethods;

  const specialTypeWatch = watch('special_type');
  const requiredProductsCount = watch(
    'display.bundle.threshold_number_of_items_in_cart'
  );
  const discountedProductsCount = watch(
    'display.bundle.max_number_of_discounted_products'
  );

  const getSubheaders = useMemo(() => {
    if (specialTypeWatch !== 'bundle') {
      return {
        requiredSubhead: undefined,
        discountedSubhead: undefined,
      };
    }

    let requiredSubhead;
    if (requiredProductsCount && requiredProductsCount > 0) {
      requiredSubhead = `${requiredProductsCount} ${pluralise(
        requiredProductsCount,
        'product'
      )} required to unlock Special.`;
    }

    let discountedSubhead;
    if (discountedProductsCount && discountedProductsCount > 0) {
      discountedSubhead = `${discountedProductsCount} ${pluralise(
        discountedProductsCount,
        'product'
      )} maximum to discount.`;
    }

    return {
      requiredSubhead,
      discountedSubhead,
    };
  }, [requiredProductsCount, discountedProductsCount, specialTypeWatch]);

  const isEditable = userCanEditSpecials;
  const isConnectedToGlobal =
    hasGlobalSpecialsAccess && special?.store_specific === false;

  const specialIsLoading =
    storeSettingsLoading || (mode !== Mode.create && specialLoading);

  const hasDuplicationErrors = useTriggerDuplicateValidation(
    ['validate_promo_code', storeId],
    specialIsLoading,
    mode === Mode.duplicate,
    trigger
  );

  const onSubmit = (formData: SpecialFormData) => {
    setHeaderError(undefined);
    const includePromoCode =
      (SHOW_PROMO_CODE_SPECIAL_TYPES as SpecialType[]).includes(
        formData['special_type']
      ) && formData['display']['promo_code_enabled'] === true;

    const includeScheduleOverride = formData['has_schedule_overrides'] === true;

    const updateOrDestroySpecialPhoto = () => {
      const photoId = special?.photo && special.photo['id'];
      return {
        id: photoId,
        file: formData['photo'][0] || '',
        // delete photo
        _destroy: photoId && !formData['photo'][0],
      };
    };

    const { photo: __omitted, ...desiredFormData } = formData;

    const requestData = {
      ...desiredFormData,
      title: formData['name'],
      promo_code: includePromoCode ? formData['promo_code'] : null,
      multiple_use_promo_code: includePromoCode
        ? !!formData['multiple_use_promo_code']
        : false,
      promo_code_max_number_of_uses: includePromoCode
        ? formData['promo_code_max_number_of_uses']
        : null,
      schedule: includeScheduleOverride
        ? formatSchedule(formData['schedule'])
        : DEFAULT_SPECIAL_SCHEDULE,
      end_date: formData['end_date'] || null,
      enabled_date_end: convert12HourTimeTo24HourTime(formData['end_time']),
      start_date: formData['start_date'] || null,
      enabled_date_start: convert12HourTimeTo24HourTime(formData['start_time']),
      discount_dollar_amount: formData['discount_dollar_amount'] || 0,
      discount_percent: formData['discount_percent'] || 0,
      discount_target_price: formData['discount_target_price'] || 0,
      photo_attributes: updateOrDestroySpecialPhoto(),
      use_store_close_time: !!formData['use_store_close_time'],
      is_duplicate: pathIncludesDuplicate,
      store_specific: special?.store_specific || true,
    };

    const specialType = formData['special_type'];

    if (specialType === 'bundle') {
      // If special is bundle, convert temporary rules to conditions so bundle special can be updated
      const discountedRules = parseRulesFromApplyTo(
        formData.display?.discounted_rules?.rules || {},
        formData.display?.discounted_rules?.apply_to || 'CONDITIONS'
      );
      const requiredRules = parseRulesFromApplyTo(
        formData.display?.required_rules?.rules || {},
        formData.display?.required_rules?.apply_to || 'CONDITIONS'
      );
      requestData['conditions'] = {
        bundle: convertRulesToBundleConditions({
          discountedRules,
          requiredRules,
          max_applications_per_cart:
            formData.display.bundle.max_applications_per_cart || 0,
          max_number_of_discounted_products:
            formData.display.bundle.max_number_of_discounted_products || 0,
          threshold_number_of_items_in_cart:
            formData.display.bundle.threshold_number_of_items_in_cart || 0,
          settings: formData.display.bundle.settings,
          type: 'bundle',
        }),
      };
    } else if (specialType === 'bulk_pricing') {
      const rules = parseRulesFromApplyTo(
        formData.display?.default_rules?.rules || {},
        formData.display?.default_rules?.apply_to || 'CONDITIONS',
        specialType
      );
      requestData['conditions'] = {
        bulk_pricing: convertRulesToBulkConditions({
          rules,
          max_applications_per_cart:
            formData.conditions?.bulk_pricing?.max_applications_per_cart || 0,
          target_weight:
            formData.conditions?.bulk_pricing?.target_weight || 'each',
        }),
      };
    } else if (specialType === 'cart_total') {
      const rawThreshold = formData.conditions.cart_total?.threshold || 0;
      const threshold =
        typeof rawThreshold === 'string'
          ? parseFloat(rawThreshold)
          : rawThreshold;

      requestData['rules'] = {
        ...parseRulesFromApplyTo(
          formData.display?.default_rules?.rules || {},
          formData.display?.default_rules?.apply_to || 'CONDITIONS'
        ),
        settings: {
          cart_threshold: threshold,
        },
      };
    } else {
      if (specialType !== 'qualified_group') {
        requestData['conditions'] = {};
      }
      requestData['rules'] = parseRulesFromApplyTo(
        formData.display?.default_rules?.rules || {},
        formData.display?.default_rules?.apply_to || 'CONDITIONS'
      );
    }

    const submitMethod = () => {
      track({
        action: modeToActionMap[mode],
        event: EventNames.EditedSpecial,
        changed_attributes: Object.keys(dirtyFields),
      });

      if (special?.store_specific === false && formData.enabled !== undefined) {
        return toggleGlobalSpecial({
          enabled: formData.enabled,
          specialId,
          storeId: Number(storeId),
        });
      }

      return mode === Mode.update
        ? updateSpecial(requestData as UpdateSpecialParams)
        : createSpecial(requestData as UpdateSpecialParams);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
      callback: () => {
        setHeaderError(genericErrorMessage);
        throw new Error(genericErrorMessage);
      },
    });
  };

  const onArchive = () => {
    setHeaderError(undefined);
    const params = {
      special_ids: [specialId],
      archive: true,
    };
    catchSubmitErrors({
      submitMethod: () => archiveSpecial(params),
      requestData: params,
      callback: () => {
        const message = `Error archiving special. Please try again.`;
        setError(FORM_ERROR_NAME as any, {
          message,
        });
        setHeaderError(message);
      },
      onValidationError: () => null,
    });
  };

  const handleClose = () => {
    navigate(
      businessPaths.storeSpecials(
        storeId,
        (previousStatus as SpecialStatus) || SpecialStatus.live
      )
    );
  };

  const isSuccessfulExit =
    isCreateSuccess ||
    isUpdateSuccess ||
    toggleGlobalSuccess ||
    archiveSpecialSuccess;

  useEffect(() => {
    if (!isSuccessfulExit) {
      return;
    }

    handleClose();
  }, [isSuccessfulExit]);

  const isJanePosSynced = special
    ? (special.pos_synced && special.pos_source === 'janepos') || false
    : false;

  const allowArchiving = useMemo(
    () =>
      shouldShowArchiveButton({
        isArchived,
        isConnectedToGlobal,
        isEnabled: special?.enabled,
        isPosSynced: special?.pos_synced,
      }),
    [isArchived, isConnectedToGlobal, special?.pos_synced, special?.enabled]
  );

  return (
    <ConfirmWrapperWithTracking
      open={isOpen}
      setOpen={handleClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      modalName={ModalNames['CreateSpecial']}
    >
      <Form.BaseForm
        name="special settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={modeToModalHeaderTitleMap[mode]}
          subtitle={storeName}
          actions={
            <Flex alignItems="center">
              {headerError && (
                <Flex mr={12}>
                  <ErrorIcon color="error" mr={8} />
                  <Typography color="error">{headerError}</Typography>
                </Flex>
              )}
              {!isArchived && (
                <SwitchWithBorder
                  label="Enabled"
                  name="enabled"
                  mr={12}
                  disabled={specialIsLoading || isMutating}
                />
              )}
              {isEditable && !specialIsLoading && (
                <>
                  {mode === Mode.update && (
                    <>
                      {allowArchiving && (
                        <ArchiveWithConfirm
                          archiveButton={(onArchiveClick) => (
                            <Button
                              mr={12}
                              onClick={onArchiveClick}
                              label="Archive"
                              variant="secondary"
                              disabled={isMutating}
                            />
                          )}
                          onArchiveSpecial={onArchive}
                        />
                      )}
                      {!special?.pos_synced && (
                        <Button
                          mr={12}
                          label="Duplicate"
                          variant="secondary"
                          to={businessPaths.storeSpecialDuplicate(
                            storeId,
                            specialId
                          )}
                        />
                      )}
                    </>
                  )}
                  {(!isArchived || (isArchived && mode === Mode.duplicate)) && (
                    <Form.SubmitButton
                      label="Publish"
                      variant="primary"
                      data-testid="special-edit-save"
                      disabled={specialIsLoading || isMutating}
                    />
                  )}
                </>
              )}
            </Flex>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <SpecialsModalProvider
            hasDuplicationErrors={hasDuplicationErrors}
            storeSettings={storePayload}
            posSyncMap={{
              posSynced: !!special?.pos_synced,
              posSource: special?.pos_source || '',
              isJanePosSynced,
            }}
            isReadOnly={isConnectedToGlobal && mode !== Mode.duplicate}
          >
            <Flex alignItems="center" flexDirection="column">
              <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
                <Card.Content>
                  <Flex p={24} flexDirection="column">
                    <DetailsCard
                      store={storePayload?.store}
                      special={special}
                      isCreateMode={mode === Mode.create}
                      isLoading={specialIsLoading}
                    />
                  </Flex>
                </Card.Content>
              </Card>
              <ConditionsCard
                fieldPrefix={
                  specialTypeWatch === 'bundle'
                    ? 'display.required_rules'
                    : 'display.default_rules'
                }
                isLoading={specialIsLoading}
                headerText={
                  (specialTypeWatch === 'bundle' &&
                    'Conditions for required products') ||
                  undefined
                }
                subHeaderText={getSubheaders['requiredSubhead']}
              />
              {specialTypeWatch === 'bundle' && (
                <ConditionsCard
                  fieldPrefix={'display.discounted_rules'}
                  isLoading={specialIsLoading}
                  headerText={
                    (specialTypeWatch === 'bundle' &&
                      'Conditions for discounted products') ||
                    undefined
                  }
                  subHeaderText={getSubheaders['discountedSubhead']}
                />
              )}
              <PromoCodeCard
                isDuplicateMode={mode === Mode.duplicate}
                isCreateMode={mode === Mode.create}
                isLoading={specialIsLoading}
                specialPromoCode={special?.promo_code}
              />
              <ScheduleCard
                isLoading={specialIsLoading && storeSettingsLoading}
                storePayload={storePayload}
              />
              <ImagesCard
                isLoading={specialIsLoading}
                isConnectedToGlobal={isConnectedToGlobal}
              />
            </Flex>
          </SpecialsModalProvider>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
