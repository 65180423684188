import styled from '@emotion/styled';
import { ErrorMessage } from '@hookform/error-message';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { hex } from 'wcag-contrast';

import { useSaveStoreImages } from '@jane/business-admin/data-access';
import type { CommunicationBanner } from '@jane/business-admin/types';
import {
  EventNames,
  FAMILIAR_MAX_IMAGE_SIZE,
  VALID_IMAGE_TYPES,
  normalizePath,
  track,
  validateImage,
} from '@jane/business-admin/util';
import {
  Banner,
  Box,
  Button,
  ErrorIcon,
  Flex,
  Form,
  InfoIcon,
  Link,
  PhotosIcon,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { ImagePreview } from '@jane/shared/util';

import { IMAGE_DISPLAY_ERROR_NAME } from './ImageDisplay';

const WCAG_RATIO = 4.5;
const ERRORS = {
  hexError: 'Color is not a valid hex color.',
  buttonA11yError: 'Button colors do not pass accessibility standards.',
} as const;

const FileDropContainer = styled.div({
  flex: '0 0 auto',
  position: 'relative',
});

const StyledBox = styled(Box)(({ theme }) => ({
  aspectRatio: '1/1',
  borderStyle: 'solid',
  color: theme.colors.grays.mid,
  height: '240px',
  backgroundColor: theme.colors.grays.ultralight,
}));

const StyledImageBox = styled(Box)(({ theme }) => ({
  aspectRatio: '1/1',
  borderStyle: 'solid',
  color: theme.colors.grays.mid,
  height: '240px',
  overflow: 'hidden',
  position: 'relative',
  isolation: 'isolate',
}));

const BackgroundImage = styled(Box)<{ image: string; imageOpacity: number }>(
  ({ image, imageOpacity }) => ({
    aspectRatio: '1/1',
    height: '240px',
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    zIndex: 1,
    opacity: imageOpacity / 100 || 1,
    position: 'absolute',
    top: 0,
    left: 0,
  })
);

const StyledFlex = styled(Flex)(() => ({
  zIndex: 3,
  opacity: 1,
  position: 'relative',
}));

const StyledButton = styled(Button)<{
  backgroundColor: string;
  textColor: string;
}>(({ backgroundColor, textColor }) => ({
  color: textColor,
  borderColor: backgroundColor,
  backgroundColor: backgroundColor,
}));

const StyledTypography = styled(Typography)<{
  textColor: string;
}>(({ textColor }) => ({
  color: textColor,
}));

const Circle = styled.div(({ color, theme }) => ({
  backgroundColor: color,
  height: '24px',
  width: '24px',
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme.colors.grays.light,
}));

const addHashToHexColor = (color: string) => {
  return color.startsWith('#') ? color : '#' + color;
};

export const ImageUploadArea = ({
  commsBannerData,
  image,
  setImage,
  required = false,
}: {
  commsBannerData?: CommunicationBanner;
  image: string | null;
  required?: boolean;
  setImage: (image: string | null) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { pathname } = useLocation();
  const { id = '' } = useParams<'id'>();

  const {
    watch,
    clearErrors,
    setError,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [
    headerText,
    buttonText,
    rawHeaderTextColor,
    imageOpacity,
    rawButtonFillColor,
    rawButtonTextColor,
    messageText,
  ] = watch([
    'title',
    'button_text',
    'header_text_color',
    'image_opacity',
    'button_fill_color',
    'button_text_color',
    'message',
  ]);

  const headerTextColor =
    rawHeaderTextColor ||
    (commsBannerData?.header_text_color &&
      addHashToHexColor(commsBannerData?.header_text_color));

  const buttonTextColor =
    rawButtonTextColor ||
    (commsBannerData?.button_text_color &&
      addHashToHexColor(commsBannerData.button_text_color));

  const buttonFillColor =
    rawButtonFillColor ||
    (commsBannerData?.button_fill_color &&
      addHashToHexColor(commsBannerData?.button_fill_color));

  const validHexColor = (hex: string, allowBlank = false) => {
    if (!hex && allowBlank) return true;

    if (hex.startsWith('#')) {
      hex = hex.substring(1);
    }

    const isValidHex =
      hex && (hex.match(/^[a-f0-9]{3}$/i) || hex.match(/^[a-f0-9]{6}$/i));

    return isValidHex;
  };

  const validateColor = (color: string, backgroundColor: string) => {
    if (!color || !backgroundColor) return true;

    const isColorAccessible = hex(color, backgroundColor) >= WCAG_RATIO;
    const error = !isColorAccessible ? ERRORS.buttonA11yError : null;

    return error || true;
  };

  useEffect(() => {
    clearErrors('wcag-error');

    if (!buttonText) return;
    if (!buttonTextColor && !buttonFillColor) return;
    if (
      !validHexColor(buttonTextColor, true) &&
      !validHexColor(buttonFillColor, true)
    )
      return;

    const error = validateColor(buttonTextColor, buttonFillColor);
    if (error !== true) {
      setError('wcag-error', { message: error });
    }
  }, [buttonFillColor, buttonTextColor]);

  const { mutateAsync: saveStoreImages } = useSaveStoreImages(id);

  const imageUpload = useCallback(
    (file: File, setImage: (url: string) => void) => {
      const updateImage = async (file: File) => {
        clearErrors(IMAGE_DISPLAY_ERROR_NAME);

        if (!validateImage(file)) {
          setError(IMAGE_DISPLAY_ERROR_NAME, {
            type: 'validate',
            message: `Invalid image type or size! Image must be of type ${VALID_IMAGE_TYPES.map(
              (x) => x.replace(/image\//, '*.')
            )
              .join(', ')
              .replace(
                /, ([^,]*)$/,
                ' or $1'
              )} and smaller than ${FAMILIAR_MAX_IMAGE_SIZE}.`,
          });

          return;
        }

        const image = await ImagePreview.parse(file);

        saveStoreImages({ image_type: 'comms_banner_image', image: image.url });
        setImage(image.url);
        setValue('image', image.url, { shouldDirty: true, shouldTouch: true });
      };

      track({
        event: EventNames.EditedPhoto,
        action: 'upload',
        file_size: `${file.size} bytes`,
        file_type: file.type,
        settings_name: 'comms_banner_image',
        url: normalizePath(pathname, id),
      });

      updateImage(file);
    },
    [saveStoreImages]
  );

  return (
    <FileDropContainer>
      {image ? (
        <>
          <StyledImageBox
            width="100%"
            borderRadius="lg"
            border="grays-light"
            borderWidth="2px"
          >
            <BackgroundImage image={image} imageOpacity={imageOpacity} />
            <StyledFlex
              mx={64}
              height="100%"
              gap={24}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              {headerText && (
                <StyledTypography
                  branded
                  textAlign="center"
                  variant="header-bold"
                  textColor={headerTextColor}
                >
                  {headerText}
                </StyledTypography>
              )}
              {messageText && (
                <StyledTypography
                  branded
                  maxLines={6}
                  textAlign="center"
                  textColor={headerTextColor}
                  whiteSpace="pre-wrap"
                >
                  {messageText}
                </StyledTypography>
              )}
              {buttonText && (
                <StyledButton
                  variant="primary"
                  label={buttonText || 'Shop Bundles'}
                  backgroundColor={buttonFillColor}
                  textColor={buttonTextColor}
                />
              )}
            </StyledFlex>
          </StyledImageBox>
          <Form.ErrorBanner name={IMAGE_DISPLAY_ERROR_NAME} />
          <Flex mt={24} gap={24}>
            <Form.TextField
              label="Header text color"
              name="header_text_color"
              endIcon={<Circle color={headerTextColor} />}
              defaultValue={headerTextColor}
              required={!!headerText}
              validate={(value: string) => {
                if (!validHexColor(value, true)) {
                  return ERRORS.hexError;
                } else {
                  return true;
                }
              }}
            />
            <Form.NumberField
              width="100%"
              label="Image opacity"
              name="image_opacity"
              defaultValue={
                imageOpacity || commsBannerData?.image_opacity || 100
              }
              endUnit="%"
              max={100}
              min={0}
            />
          </Flex>
          <Flex mt={24} gap={24}>
            <Form.TextField
              label="Button fill color"
              name="button_fill_color"
              endIcon={<Circle color={buttonFillColor} />}
              defaultValue={buttonFillColor}
              required={!!buttonText}
              validate={(value: string) => {
                return validHexColor(value, true) ? true : ERRORS.hexError;
              }}
            />
            <Form.TextField
              label="Button text color"
              name="button_text_color"
              endIcon={<Circle color={buttonTextColor} />}
              defaultValue={buttonTextColor}
              required={!!buttonText}
              validate={(value: string) => {
                return validHexColor(value, true) ? true : ERRORS.hexError;
              }}
            />
          </Flex>
          {errors['wcag-error'] ? (
            <ErrorMessage
              errors={errors}
              name={'wcag-error'}
              render={({ message }: { message: string }) => (
                <Banner
                  full
                  icon={<ErrorIcon />}
                  label={message}
                  mt={24}
                  mb={8}
                  variant="error"
                  actions={
                    <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
                      WCAG's Guidelines
                    </Link>
                  }
                />
              )}
            />
          ) : (
            <Banner
              full
              icon={<InfoIcon />}
              label={
                'Be sure to adhere to accessibility standards in regards to color contrast.'
              }
              mt={24}
              variant="info"
              actions={
                <Link href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html">
                  WCAG's Guidelines
                </Link>
              }
            />
          )}
        </>
      ) : (
        <StyledBox
          width="100%"
          borderRadius="lg"
          border="grays-light"
          borderWidth="2px"
        >
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap={12}
            height={'100%'}
          >
            <Flex
              flexDirection="column"
              alignItems="center"
              ariaLabel="add photo"
              onClick={() => {
                fileInputRef.current && fileInputRef.current.click();
              }}
            >
              <PhotosIcon mb={8} />
              <Typography color="grays-black" variant="body-bold">
                Add photo
                {required ? (
                  <Typography as="span" color="error-dark">
                    *
                  </Typography>
                ) : null}
              </Typography>
            </Flex>
          </Flex>
        </StyledBox>
      )}
      <label style={{ display: 'none' }}>
        Image upload
        <input
          ref={fileInputRef}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept={'image/*'}
          onChange={(event) => {
            event.preventDefault();
            const files = event.currentTarget.files;
            if (files && files[0]) {
              imageUpload(files[0], setImage);
            }
          }}
        />
      </label>
    </FileDropContainer>
  );
};
