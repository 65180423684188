import omit from 'lodash/omit';
import pluralise from 'pluralise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { useStores } from '@jane/business-admin/data-access';
import type { GlobalSpecialStore } from '@jane/business-admin/types';
import { MODAL_CARD_WIDTH, concatAddress } from '@jane/business-admin/util';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  Banner,
  Button,
  Card,
  EditIcon,
  ErrorIcon,
  Flex,
  Form,
  InfoIcon,
  Modal,
  Skeleton,
  Tag,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

const StoreRow = ({
  handleChange,
  index,
  isChecked,
  isDisabled,
  isLast = false,
  store,
  storeAddress,
}: {
  handleChange: (checked: boolean) => void;
  index: number;
  isChecked?: boolean;
  isDisabled?: boolean;
  isLast?: boolean;
  store: GlobalSpecialStore;
  storeAddress: string;
}) => {
  const tagProps = store.recreational
    ? ({
        label: 'Rec',
        color: 'primary-dark',
        background: 'primary-light',
      } as const)
    : ({
        label: 'Med',
        color: 'palm-dark',
        background: 'palm-light',
      } as const);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex my={16} alignItems="center" gap={24}>
          <Tag {...tagProps} />
          <div>
            <Typography variant="body-bold">{store.name}</Typography>
            <Typography color="grays-mid">{storeAddress}</Typography>
          </div>
        </Flex>
        <Flex gap={16} mx={8}>
          <Form.SwitchField
            label={`stores.${index}.enabled`}
            labelHidden
            name={`stores.${index}.enabled`}
            defaultChecked={isChecked}
            checked={isChecked}
            onChange={handleChange}
            shouldUnregister={false}
            disabled={isDisabled}
          />
          <Typography>{`${isChecked ? 'Enabled' : 'Disabled'}`}</Typography>
        </Flex>
      </Flex>
      {!isLast && <Modal.ContentDivider padding={false} />}
    </>
  );
};

const lengthEightArray = Array(8).fill(null);

const StoreSelectorSkeleton = () => {
  return (
    <Skeleton animate>
      <Skeleton.Bone height={48} mt={40} mb={16} />
      {lengthEightArray.map((_, i) => (
        <Skeleton.Bone key={`skeleton-${i}`} height={48} my={16} />
      ))}
    </Skeleton>
  );
};

export interface StoreSelectModalProps {
  disableSubmit?: boolean;
  specialIsLoading: boolean;
  submitButtonType?: SUBMIT_BUTTON_VARIANTS;
  subtitle?: string;
}

export const StoreSelectorCard = ({
  specialIsLoading,
}: StoreSelectModalProps) => {
  const [disableBulkActions, setDisableBulkActions] = useState(false);
  const [storeSelectModalOpen, setStoreSelectModalOpen] = useState(false);
  const [showToggleOffWarning, setShowToggleOffWarning] = useState(false);

  const { data: storesData, isFetching: isFetchingStores } = useStores();

  const {
    control,
    setValue,
    clearErrors,
    setError,
    watch,
    formState: { errors },
  } = useFormContext();
  const { fields: storeSelectFields, replace } = useFieldArray({
    control,
    name: 'stores',
    keyName: '_key',
  });

  const storesWatch = watch('stores');

  const [showAll, setShowAll] = useState(false);

  const isTogglingAll = useRef(false);

  useEffect(() => {
    isTogglingAll.current = false;
  }, [storesWatch]);

  const stores = useMemo(
    () =>
      storesWatch?.map(({ id, ...restStore }: any) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storeSelectFields]
  );

  const storesForSelectModal = useMemo(
    () =>
      storesData?.map(({ id, ...restStore }) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storesData]
  );

  const StoreIds = {
    asString: (id: number | string) => (typeof id === 'number' ? `${id}` : id),
    asNumber: (id: number | string) =>
      typeof id === 'string' ? parseInt(id, 10) : id,
  };

  const selectedStoreIds: string[] = (storeSelectFields as GlobalSpecialStore[])
    .filter(({ can_edit }) => can_edit)
    .map((store: GlobalSpecialStore) => StoreIds.asString(store.id));

  const isStoreSelected = useCallback(
    (storeId: number | string) => {
      const foundStore: GlobalSpecialStore | undefined = (
        storesWatch as GlobalSpecialStore[]
      ).find(
        (store: GlobalSpecialStore) =>
          StoreIds.asNumber(store.id) === StoreIds.asNumber(storeId)
      );

      return foundStore?.enabled || false;
    },
    [JSON.stringify(storesWatch)]
  );

  const numStoresSelected = (storesWatch as GlobalSpecialStore[]).filter(
    (store: GlobalSpecialStore) => store.enabled
  ).length;

  const DEFAULT_STORES_IN_VIEW = 10;

  const handleSelectAll = (selected: boolean) => {
    const update = (storesWatch as GlobalSpecialStore[]).map(
      (store: GlobalSpecialStore) => ({
        ...store,
        enabled: selected,
      })
    );

    setShowToggleOffWarning(!selected);

    replace(update);
  };

  const handleShowAll = () => {
    setShowAll((prevShowAll) => !prevShowAll);
  };
  const storesInViewIndex = !showAll
    ? DEFAULT_STORES_IN_VIEW
    : storeSelectFields.length;
  const storesInView = useMemo(
    () =>
      (storeSelectFields as GlobalSpecialStore[]).slice(0, storesInViewIndex),
    [storeSelectFields, showAll]
  );

  const handleRowSelect = ({
    index,
    enabled,
  }: {
    enabled: boolean;
    index: number;
  }) => {
    if (isTogglingAll.current) return;

    setValue(`stores.${index}.enabled`, enabled);
  };

  const storesList = useMemo(() => {
    return (
      <>
        {storesInView.map((store: GlobalSpecialStore, index: number) => {
          const isChecked = isStoreSelected(store.id);
          if (!store.can_edit) {
            setDisableBulkActions(true);
          }
          const isLast =
            stores.length <= 10 && index === storesInView.length - 1;

          return (
            <StoreRow
              key={store._key}
              isLast={isLast}
              isDisabled={!store.can_edit}
              isChecked={isChecked}
              index={index}
              store={store}
              storeAddress={concatAddress(store)}
              handleChange={(enabled) => handleRowSelect({ index, enabled })}
            />
          );
        })}

        {stores && stores.length > 10 && (
          <Flex alignItems="center" justifyContent="center" mt={24}>
            <Button
              mr={12}
              onClick={handleShowAll}
              label={`${showAll ? 'Show less' : 'Show all'}`}
              variant="primary"
            />
          </Flex>
        )}
      </>
    );
  }, [JSON.stringify(storesInView), isStoreSelected]);

  useEffect(() => {
    if (!(storesInView.length > 0)) {
      setError('store_ids', {
        message: 'No stores selected for special',
      });
    } else {
      clearErrors('store_ids');
    }
  }, [setError, JSON.stringify(storesInView)]);

  useEffect(() => {
    const allDisabled = storesWatch?.every((store: any) => !store.enabled);

    setShowToggleOffWarning(allDisabled);
  }, [JSON.stringify(storesWatch)]);

  const onSelectStores = (storeIds: (string | number)[]) => {
    const currentStoreIds = storesInView.map(
      (store: GlobalSpecialStore) => store.id && StoreIds.asNumber(store.id)
    );

    const isCurrentStore = (id: number) => {
      return currentStoreIds.indexOf(id) >= 0;
    };

    /**
      Update stores list with newly selected stores but we need to compare
      against stores already shown to user since lists come from separate sources
      */
    const newStores: any =
      storesData
        ?.filter(
          (store: any) =>
            store.id !== undefined &&
            storeIds.includes(StoreIds.asNumber(store.id))
        )
        .map((store: any) => ({
          ...omit(store, [
            'allow_group_specials',
            'cart_limit_policy',
            'photo',
          ]),
          can_edit: true,
          enabled: isCurrentStore(store.id)
            ? storesWatch.find(
                (currentStore: GlobalSpecialStore) =>
                  currentStore.id === store.id
              ).enabled
            : true,
        })) || [];

    // User shouldn't be able to deselect uneditable stores using the store select modal
    const uneditableStores = storesWatch.filter(
      ({ can_edit }: GlobalSpecialStore) => !can_edit
    );
    // Update form field
    replace([...newStores, ...uneditableStores]);

    clearErrors();

    setStoreSelectModalOpen(false);
  };

  const storesSubtext = `Available at ${numStoresSelected} ${pluralise(
    numStoresSelected,
    'store'
  )}`;

  return (
    <>
      <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
        <Card.Content>
          <Flex
            mb={8}
            justifyContent="space-between"
            p={24}
            flexDirection="column"
          >
            {!!errors['stores']?.message && (
              <Banner
                full
                variant="error"
                icon={<ErrorIcon />}
                label={errors['stores']?.message
                  ?.toString()
                  ?.replace(/stores/, '')}
                mt={16}
                mb={16}
              />
            )}
            <Flex justifyContent="space-between">
              <Flex mb={showToggleOffWarning ? 24 : 40} flexDirection="column">
                <Typography variant="header-bold">Stores</Typography>
                <Typography color="grays-mid">{storesSubtext}</Typography>
              </Flex>
              <Flex>
                {storesWatch.length > 1 && (
                  <>
                    <Button
                      mr={12}
                      onClick={() => handleSelectAll(true)}
                      label="Enable all"
                      variant="tertiary"
                      disabled={disableBulkActions}
                    />
                    <Button
                      mr={12}
                      onClick={() => handleSelectAll(false)}
                      label="Disable all"
                      variant="tertiary"
                      disabled={disableBulkActions}
                    />
                  </>
                )}
                <Button.Icon
                  icon={<EditIcon />}
                  onClick={() => setStoreSelectModalOpen(true)}
                  label="Edit stores"
                  variant="secondary"
                  data-testid="stores-edit"
                />
              </Flex>
            </Flex>
            {storesInView.length && showToggleOffWarning ? (
              <Banner
                icon={<InfoIcon />}
                label="Toggling off all stores will disable the special"
                onDismiss={() => setShowToggleOffWarning(false)}
                typography="body"
                variant="info"
              />
            ) : null}
            {!specialIsLoading ? (
              <Flex
                flexDirection="column"
                mt={showToggleOffWarning ? 12 : 0}
                mb={32}
              >
                {storesList}
              </Flex>
            ) : (
              <StoreSelectorSkeleton />
            )}
          </Flex>
        </Card.Content>
      </Card>

      {storeSelectModalOpen && (
        <StoreSelectModal
          storesData={storesForSelectModal || []}
          isFetchingStores={isFetchingStores}
          onSubmit={onSelectStores}
          selectedStoreIds={selectedStoreIds}
          submitButtonType={SUBMIT_BUTTON_VARIANTS.select}
          closeModal={() => setStoreSelectModalOpen(false)}
        />
      )}
    </>
  );
};
