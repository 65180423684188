import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useContext, useMemo } from 'react';

import {
  GlobalSpecialsModalContext,
  SpecialsModalContext,
} from '@jane/business-admin/providers';
import type { StoreSettingsPayload } from '@jane/business-admin/types';
import {
  MODAL_CARD_WIDTH,
  getFormErrorMessages,
} from '@jane/business-admin/util';
import {
  Banner,
  Card,
  ErrorIcon,
  Flex,
  Form,
  Skeleton,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';

import { DateAndTimePicker } from './DateAndTimePicker';
import { ScheduleOverride } from './ScheduleOverride';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(utc);
dayjs.extend(timezone);

export const ScheduleCard = ({
  isLoading,
  storePayload,
}: {
  isLoading: boolean;
  storePayload?: StoreSettingsPayload;
}) => {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const has_schedule_overrides = watch('has_schedule_overrides');
  const { hasDuplicationErrors, isReadOnly } = useContext(SpecialsModalContext);
  const { isDisabled } = useContext(GlobalSpecialsModalContext);

  const dateErrorFields = ['start_date', 'end_date'];
  const condensedErrors = useMemo(() => {
    if (hasDuplicationErrors) {
      const reduced = getFormErrorMessages(errors, dateErrorFields);
      return reduced.join('. ');
    }
    return null;
  }, [hasDuplicationErrors, JSON.stringify(errors)]);

  const scheduleSubtext =
    'Schedule when this special should be active — no action is needed to run \
    this special indefinitely. All times shown are in the time zone of the store.';

  return (
    <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={16}>
      <Card.Content>
        <Flex p={24} flexDirection="column">
          <Flex mb={8}>
            <Typography variant="header-bold">Schedule</Typography>
            <Typography variant="header" ml={8} color="grays-mid">
              (Optional)
            </Typography>
          </Flex>
          <Typography color="grays-mid">{scheduleSubtext}</Typography>
          {hasDuplicationErrors && condensedErrors && (
            <Banner
              mt={20}
              variant="error"
              label={condensedErrors}
              icon={<ErrorIcon />}
            />
          )}
          {!isLoading ? (
            <>
              <Form.CheckboxField
                label="Set start date and time"
                name="enabled_date_start_checked"
                onChange={(val) => {
                  if (val === false) {
                    setValue('start_date', '', { shouldDirty: true });
                    setValue('start_time', '', { shouldDirty: true });
                  }
                }}
                mt={24}
                disabled={isDisabled || isReadOnly}
              />
              <Form.CheckboxField
                label="Set end date and time"
                name="enabled_date_end_checked"
                onChange={(val) => {
                  if (val === false) {
                    setValue('end_date', '', { shouldDirty: true });
                    setValue('end_time', '', { shouldDirty: true });
                  }
                }}
                mt={24}
                disabled={isDisabled || isReadOnly}
              />
              <DateAndTimePicker
                disabled={isDisabled || isReadOnly || false}
                variant="start"
                timezone={storePayload?.store?.time_zone_identifier}
                storeRetailSchedule={storePayload?.retail_schedule}
              />
              <DateAndTimePicker
                disabled={isDisabled || isReadOnly || false}
                timezone={storePayload?.store?.time_zone_identifier}
                variant="end"
                storeRetailSchedule={storePayload?.retail_schedule}
              />
              <Form.SwitchField
                label="Enable daily schedule overrides"
                name="has_schedule_overrides"
                mt={40}
                mb={has_schedule_overrides && 24}
                disabled={isDisabled || isReadOnly}
              />
              {has_schedule_overrides ? (
                <ScheduleOverride
                  disabled={isDisabled || isReadOnly || false}
                />
              ) : null}
            </>
          ) : (
            <Skeleton animate>
              <Skeleton.Bone width="30%" mt={24} />
              <Skeleton.Bone width="30%" mt={24} />
              <Skeleton.Bone width="40%" mt={40} />
            </Skeleton>
          )}
        </Flex>
      </Card.Content>
    </Card>
  );
};
