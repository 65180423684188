import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { buildDiscountableProductsFilter } from '@jane/search/util';
import { MenuBreadcrumbs } from '@jane/shared-ecomm/components';
import { useGetSpecialQualifyingStatus } from '@jane/shared-ecomm/data-access';
import { useMenu, useSpecials } from '@jane/shared-ecomm/providers';
import type {
  MenuProduct,
  PendingCartProduct,
  QualifyingStatus,
} from '@jane/shared/models';
import { Box, Flex, useMobileMediaQuery } from '@jane/shared/reefer';
import type { Breadcrumb, StoreSpecial } from '@jane/shared/types';
import { insertIfArr } from '@jane/shared/util';

import { MenuFiltersAndProductsGrid } from '../../MenuComponents/MenuFiltersAndProductsGrid';
import { MenuTitle } from '../../MenuComponents/MenuTitle';
import { useComposeMenuFilters } from '../../hooks/useComposeMenuFilters';
import {
  filterBulkWeights,
  getApplicablePriceIds,
  getSpecialType,
} from './MenuSpecialDetail.util';
import { MenuSpecialDetailBundleProgress } from './MenuSpecialDetailBundleProgress';
import { MenuSpecialDetailHeader } from './MenuSpecialDetailHeader';
import { MenuSpecialDetailProgressBar } from './MenuSpecialDetailProgressBar';

export const MenuSpecialDetail = () => {
  const { state } = useLocation();
  const isMobile = useMobileMediaQuery({});
  const { special_id: specialId } = useParams<{
    special_id: string;
  }>();

  const {
    appInfo: { bundlePossibilitiesState },
    cart,
    dispatches: { openCart },
    paths,
    store,
  } = useMenu();

  const { specials = [], loadingSpecials } = useSpecials();

  const currentSpecial = specials.find(
    (special) => String(special.id) === specialId
  );

  const { data: qualifyingStatusData, refetch: fetchStatus } =
    useGetSpecialQualifyingStatus({
      cartUuid: cart.uuid || '0',
      special: currentSpecial,
      storeId: store.id,
    });

  const qualifyingStatus = qualifyingStatusData?.qualifying_status;

  const bundlePossibilities = bundlePossibilitiesState?.bundlePossibilities;
  const filterDiscountableProducts =
    bundlePossibilitiesState?.filterDiscountableProducts ?? false;
  const bundleFilter = buildDiscountableProductsFilter(
    filterDiscountableProducts,
    Array.from(bundlePossibilities?.discountable_product_ids ?? [])
  );

  const storeId = store?.id;

  const { special_type } = currentSpecial || {};
  const isBulk = special_type === 'bulk_pricing';
  const isBundle = special_type === 'bundle';
  const isCartTotal = special_type === 'cart_total';
  const pageTitle = currentSpecial?.title || '';

  const defaultStatusData = {
    qualifying_status: 'not_qualified',
    special: {
      id: specialId,
      description: currentSpecial?.description,
      title: currentSpecial?.title,
      special_type: currentSpecial?.special_type,
      photo: currentSpecial?.photo,
      store_id: storeId,
    },
  };

  useEffect(() => {
    document
      .getElementById('progress-container-scroll-position')
      ?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });

    if (qualifyingStatus === 'applied') {
      openCart();
    }
  }, [qualifyingStatus]);

  useEffect(() => {
    fetchStatus();
  }, [cart.uuid, cart.products]);

  const specialFilter = getSpecialType(
    currentSpecial?.special_type,
    qualifyingStatus
  );

  const applicablePriceIds = getApplicablePriceIds(
    currentSpecial,
    qualifyingStatus
  );

  const staticFilters = useComposeMenuFilters([
    `applicable_${specialFilter}:${currentSpecial?.id}`,
    bundleFilter,
  ]);

  const fromFeatured = state?.breadcrumbs?.some(
    (crumb: Breadcrumb) => crumb.title === 'Featured'
  );

  const breadcrumbs = [
    { url: '/', title: 'Home' },
    { url: paths.menu(), title: store.name },
    ...insertIfArr(!fromFeatured, {
      url: paths.menuCategory('specials'),
      title: store.specials_row_configs?.custom_display_name || 'Specials',
    }),
  ];

  const nextBreadcrumbs = [
    {
      url: paths.menuCategory('specials'),
      title: store.specials_row_configs?.custom_display_name || 'Specials',
    },
    {
      title: pageTitle,
      url: paths.menuSpecial({
        id: currentSpecial?.id || 0,
        title: pageTitle,
      }),
    },
  ];

  const additionalProductFilter = (
    products: MenuProduct[] | SmartSortProduct[]
  ) => {
    return filterBulkWeights({
      cartProducts: cart.products as PendingCartProduct[],
      currentSpecial,
      products,
      qualifyingStatus: qualifyingStatusData,
    });
  };

  const maxWeightOption =
    qualifyingStatusData?.target_weight && qualifyingStatusData?.product_weight
      ? qualifyingStatusData.target_weight - qualifyingStatusData.product_weight
      : null;

  if (!currentSpecial && !loadingSpecials) {
    return <Navigate to={paths.menu()} replace />;
  }

  return (
    <Box>
      <Flex
        flexDirection="column"
        px={isMobile ? 0 : 64}
        pb={isMobile ? 24 : 48}
        gap={isMobile ? 0 : 24}
      >
        {!isMobile && (
          <MenuBreadcrumbs
            breadcrumbs={breadcrumbs}
            title={pageTitle}
            pb={24}
          />
        )}

        {currentSpecial && <MenuSpecialDetailHeader special={currentSpecial} />}

        {(isBulk || isCartTotal) && (
          <MenuSpecialDetailProgressBar
            status={
              (qualifyingStatusData || defaultStatusData) as QualifyingStatus
            }
            special={currentSpecial as StoreSpecial}
          />
        )}

        {isBundle && (
          <MenuSpecialDetailBundleProgress
            status={
              (qualifyingStatusData || defaultStatusData) as QualifyingStatus
            }
            special={currentSpecial as StoreSpecial}
          />
        )}
      </Flex>

      {currentSpecial && (
        <Box>
          <MenuTitle px={isMobile ? 24 : 64} />
          <MenuFiltersAndProductsGrid
            {...(isBulk && {
              additionalProductFilter,
              maxWeightOption,
            })}
            breadcrumbs={nextBreadcrumbs}
            menuCategory="specials"
            pageTitle={pageTitle}
            showOnlyWeights={applicablePriceIds}
            staticFilters={staticFilters}
            withoutDrawerOnAddToCart
          />
        </Box>
      )}
    </Box>
  );
};
