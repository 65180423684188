import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

interface Props {
  children: ReactNode;
  hasDuplicationErrors?: boolean;
  isDisabled?: boolean;
}

export const GlobalSpecialsModalContext = React.createContext<
  Omit<Props, 'children'>
>({});

export const GlobalSpecialsModalProvider = ({
  children,
  hasDuplicationErrors,
  isDisabled,
}: Props) => {
  const contextValue = useMemo(
    () => ({
      hasDuplicationErrors,
      isDisabled,
    }),
    [hasDuplicationErrors, isDisabled]
  );

  return (
    <GlobalSpecialsModalContext.Provider value={contextValue}>
      {children}
    </GlobalSpecialsModalContext.Provider>
  );
};
