// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LqDJk3VJzqMRPc7zu_Y1 {\n  position: relative;\n}\n\n.v3cwFFEHdk1JeK6A8IGI {\n  background: transparent;\n  height: auto;\n  position: absolute;\n  width: 100%;\n  z-index: var(--layers-popover);\n}\n\n.T1GVhOghUL8_q0_xBfbT {\n  background-color: var(--colors-grays-white);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-hard);\n  overflow-y: hidden;\n  position: relative;\n  margin-top: 12px;\n}\n\n.SYdKoK33MQ1OzXpLpERA {\n  height: 100%;\n  max-height: 40vh;\n  overflow-y: auto;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/forms/fields/textField/autocomplete/textFieldAutocomplete.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,8BAA8B;AAChC;;AAEA;EACE,2CAA2C;EAC3C,sCAAsC;EACtC,+BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".textFieldAutocomplete_container {\n  position: relative;\n}\n\n.textFieldAutocomplete_alignment {\n  background: transparent;\n  height: auto;\n  position: absolute;\n  width: 100%;\n  z-index: var(--layers-popover);\n}\n\n.textFieldAutocomplete_options {\n  background-color: var(--colors-grays-white);\n  border-radius: var(--border-radius-sm);\n  box-shadow: var(--shadows-hard);\n  overflow-y: hidden;\n  position: relative;\n  margin-top: 12px;\n}\n\n.textFieldAutocomplete_list {\n  height: 100%;\n  max-height: 40vh;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textFieldAutocomplete_container": "LqDJk3VJzqMRPc7zu_Y1",
	"textFieldAutocomplete_alignment": "v3cwFFEHdk1JeK6A8IGI",
	"textFieldAutocomplete_options": "T1GVhOghUL8_q0_xBfbT",
	"textFieldAutocomplete_list": "SYdKoK33MQ1OzXpLpERA"
};
export default ___CSS_LOADER_EXPORT___;
