import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

import { PageLayout } from '@jane/shared-ecomm/components';
import { useMenu } from '@jane/shared-ecomm/providers';
import { getCardCarouselDimensions } from '@jane/shared/components';
import {
  Box,
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  Hide,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import type { MenuCategoryPath } from '@jane/shared/types';
import { sleep } from '@jane/shared/util';

import { MenuTabButton } from './MenuTabButton';
import { MenuTabGrid, MenuTabScrollArea } from './MenuTabs.styles';
import { MENU_CATEGORY_TABS } from './menuCategoryTabs';

export const MenuTabs = ({ tabs }: { tabs?: MenuCategoryPath[] }) => {
  const {
    appInfo: { appMode, isEmbed },
  } = useMenu();
  const isMobile = useMobileMediaQuery({});

  const [scrollAreaNode, setScrollAreaNode] = useState<null | HTMLDivElement>(
    null
  );
  const scrollAreaRef = useCallback((scrollAreaNode: null | HTMLDivElement) => {
    setScrollAreaNode(scrollAreaNode);
  }, []);

  const [width, setWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const setScrollLeftDebounced = debounce(setScrollLeft, 100);

  useEffect(() => {
    if (!scrollAreaNode) return;

    const measureScrollArea = () => {
      setScrollWidth(scrollAreaNode.scrollWidth);
      setWidth(scrollAreaNode.getBoundingClientRect().width);
    };
    measureScrollArea();

    const observer = new ResizeObserver(() => {
      measureScrollArea();
    });
    observer.observe(scrollAreaNode);

    return () => {
      observer.disconnect();
    };
  }, [scrollAreaNode]);

  const { cardGap: tabGap, cardWidth: tabWidth } = getCardCarouselDimensions(
    'fixed',
    82,
    24,
    width
  );

  const scrollCarousel = (direction = 'right') => {
    if (!scrollAreaNode) {
      return;
    }
    const scrollModifier = direction === 'right' ? 1 : -1;
    scrollAreaNode.scrollBy({
      behavior: 'smooth',
      left: scrollModifier * width,
    });
  };

  useEffect(() => {
    const scrollAnimation = async () => {
      if (scrollAreaNode && tabs?.length) {
        scrollAreaNode.scrollTo?.({ left: 150, behavior: 'smooth' });
        await sleep(1000);
        scrollAreaNode.scrollTo?.({ left: 0, behavior: 'smooth' });
      }
    };

    scrollAnimation();
  }, [scrollAreaNode, tabs?.length]);

  const hideRightControl =
    Math.ceil(scrollLeft) + Math.ceil(width) >= Math.floor(scrollWidth);
  const hideLeftControl = scrollLeft === 0;

  const menuTabs = tabs?.map((category, i) => (
    <MenuTabButton
      key={category}
      isFirstTab={i === 0}
      name={category}
      item={MENU_CATEGORY_TABS[category]}
      width={tabWidth}
    />
  ));

  return (
    <PageLayout
      as="nav"
      pt={appMode === 'brandEmbed' ? 24 : 0}
      pl={isMobile ? 24 : 0}
      pb={isMobile || isEmbed ? 24 : 48}
      minHeight={75}
    >
      {!isMobile && (
        <Box
          position="absolute"
          background="grays-ultralight"
          zIndex="var(--layers-visible)"
          py={12}
          px={8}
          left={0}
          top={0}
        >
          <Hide isHidden={hideLeftControl} data-testid="menu-tab-back">
            <Button.Icon
              ariaLabel="Back"
              icon={<ChevronLeftIcon />}
              onClick={() => scrollCarousel('left')}
            />
          </Hide>
        </Box>
      )}

      <Box px={!isMobile ? 64 : 0} width="100%">
        <MenuTabScrollArea
          ref={scrollAreaRef}
          onScroll={(event) =>
            setScrollLeftDebounced((event.target as HTMLElement).scrollLeft)
          }
        >
          <MenuTabGrid gap={tabGap} width={tabWidth}>
            {menuTabs}
          </MenuTabGrid>
        </MenuTabScrollArea>
      </Box>

      {!isMobile && (
        <Box
          position="absolute"
          background="grays-ultralight"
          zIndex="var(--layers-visible)"
          py={12}
          px={8}
          right={0}
          top={0}
        >
          <Hide isHidden={hideRightControl} data-testid="menu-tab-forward">
            <Button.Icon
              ariaLabel="Forward"
              icon={<ChevronRightIcon />}
              onClick={() => scrollCarousel('right')}
            />
          </Hide>
        </Box>
      )}
    </PageLayout>
  );
};
