import type { ColumnDef, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import type { AbbreviatedGlobalSpecialsV2 } from '@jane/business-admin/types';
import {
  EventNames,
  getDiscountAmount,
  track,
} from '@jane/business-admin/util';
import { Typography } from '@jane/shared/reefer';
import type { CheckboxFieldProps } from '@jane/shared/reefer';

import { SortableHeader } from '../shared/tables/SortableHeader';
import { ActionsMenuCell } from '../specials/ActionsMenuCell';
import { SpecialCell } from '../specials/SpecialCell';
import { SpecialTypeCell } from '../specials/SpecialTypeCell';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    h: '1 hr',
    hh: '%d hrs',
    d: '1 day',
    dd: '%d days',
    w: '1 week',
    ww: '%d weeks',
  },
});

interface ToggleSpecialEnabledParams {
  enabled: boolean;
  specialId: string;
}

export const buildGlobalSpecialsColumns = ({
  bulkEditModalOpen,
  hasArchivedFilter,
  isFetched,
  sorting,
  toggleSpecialEnabled,
  totalRowCount,
  onArchiveSpecial,
  onEditStores,
}: {
  bulkEditModalOpen: boolean;
  hasArchivedFilter: boolean;
  isFetched: boolean;
  onArchiveSpecial: (id: number) => void;
  onEditStores: (special: Partial<AbbreviatedGlobalSpecialsV2>) => void;
  sorting: SortingState;
  toggleSpecialEnabled: ({
    specialId,
    enabled,
  }: ToggleSpecialEnabledParams) => Promise<any>;
  totalRowCount: number;
}): ColumnDef<AbbreviatedGlobalSpecialsV2>[] => {
  return [
    {
      accessorKey: 'title',
      enableColumnFilter: true,
      id: 'title',
      header: ({ column }) => (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText={!isFetched ? '# Specials' : `${totalRowCount} Specials`}
        />
      ),
      cell: ({ row }) => {
        const toggleSelected: Required<CheckboxFieldProps>['onClick'] = (
          event
        ) => {
          const checked = (event.target as HTMLInputElement).checked;
          track({
            event: checked
              ? EventNames.SelectedObject
              : EventNames.DeselectedObject,
            objects: row.original.title || '',
            trigger_source_id: 'checkbox',
          });

          event.stopPropagation();
          row.toggleSelected();
        };

        return (
          <SpecialCell
            row={row}
            onToggleSelected={toggleSelected}
            showToggle={bulkEditModalOpen}
            bulkEditModalOpen={bulkEditModalOpen}
            isGlobalSpecials={true}
            onChangeEnabled={() => {
              toggleSpecialEnabled({
                specialId: row.original.id.toString(),
                enabled: !row.original.enabled,
              });
            }}
          />
        );
      },
    },
    {
      id: 'special_type',
      enableColumnFilter: true,
      footer: 'Type',
      header: () => <Typography variant="caps">Type</Typography>,
      cell: ({ row }) => (
        <SpecialTypeCell specialType={row.original.special_type} />
      ),
      accessorKey: 'special_type',
    },
    {
      id: 'discount',
      enableColumnFilter: false,
      accessorKey: 'discount',
      footer: 'Discount',
      header: () => <Typography variant="caps">Discount</Typography>,
      cell: ({ row }) => {
        const amount = getDiscountAmount(row.original);
        return (
          <Typography minWidth={110} color="grays-black">
            {amount}
          </Typography>
        );
      },
    },
    {
      id: 'next_occurrence',
      enableColumnFilter: false,
      accessorKey: 'next_occurrence',
      footer: 'Starts in',
      header: ({ column }) => (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText="Starts in"
        />
      ),
      cell: ({ row }) => {
        if (!row.original?.next_occurrence) return null;
        if (typeof row.original.next_occurrence === 'string')
          return <Typography>{row.original.next_occurrence}</Typography>;

        const parsedDate = new Date(row.original.next_occurrence * 1000);
        const hoursUntil = dayjs(parsedDate).diff(dayjs(), 'hour');
        const relativeTimeUntil = dayjs(parsedDate).fromNow(true);

        const color = hoursUntil < 24 ? 'primary' : 'grays-black';
        const variant = hoursUntil < 24 ? 'body-bold' : 'body';

        return (
          <Typography color={color} variant={variant}>
            {relativeTimeUntil}
          </Typography>
        );
      },
    },
    {
      id: 'start_date',
      enableColumnFilter: false,
      accessorKey: 'start_date',
      footer: 'Start date',
      header: ({ column }) => (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText="Start date"
        />
      ),
      cell: ({ row }) => {
        const date = row.original.start_time
          ? new Date(row.original.start_time).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'numeric',
              year: '2-digit',
            })
          : 'None';
        return (
          <Typography
            color={row.original.start_time ? 'grays-black' : 'grays-mid'}
            minWidth={160}
          >
            {date}
          </Typography>
        );
      },
    },
    {
      id: 'end_date',
      enableColumnFilter: false,
      accessorKey: 'end_date',
      footer: 'End date',
      header: ({ column }) => (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText="End date"
        />
      ),
      cell: ({ row }) => {
        const date = row.original.end_time
          ? new Date(row.original.end_time).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'numeric',
              year: '2-digit',
            })
          : 'None';
        return (
          <Typography
            color={row.original.end_time ? 'grays-black' : 'grays-mid'}
            minWidth={160}
          >
            {date}
          </Typography>
        );
      },
    },
    {
      id: 'archived_at',
      enableColumnFilter: false,
      accessorKey: 'archived_at',
      footer: 'Archived on',
      header: ({ column }) => (
        <SortableHeader
          sorting={sorting}
          column={column}
          headerText="Archived on"
        />
      ),
      cell: ({ row }) => {
        const date = row.original.archived_at
          ? dayjs.unix(Number(row.original.archived_at)).format('M/D/YY')
          : 'None';
        return (
          <Typography minWidth={160} color="grays-black">
            {date}
          </Typography>
        );
      },
    },
    {
      id: 'promo_code',
      enableColumnFilter: false,
      accessorKey: 'promo_code',
      footer: 'Promo code',
      header: () => <Typography variant="caps">Promo code</Typography>,
      cell: ({ row }) => (
        <Typography
          color={row.original.promo_code ? 'grays-black' : 'grays-mid'}
          minWidth={110}
        >
          {row.original.promo_code ?? 'None'}
        </Typography>
      ),
    },
    {
      id: 'location_count',
      enableColumnFilter: false,
      accessorKey: 'location_count',
      footer: 'Locations',
      header: () => <Typography variant="caps">Locations</Typography>,
      cell: ({ row }) => (
        <Typography color="grays-black">
          {/* {(row.original.stores?.length || 0).toString()} */}
          {(
            row.original.stores?.filter((store) => store.enabled === true)
              .length || 0
          ).toString()}
        </Typography>
      ),
    },
    {
      id: 'actions',
      enableColumnFilter: false,
      accessorKey: 'actions',
      footer: 'Actions',
      header: '',
      cell: ({ row }) => {
        const hasUneditableStores =
          row.original.stores?.some(({ can_edit }) => !can_edit) || false;
        return (
          !bulkEditModalOpen && (
            <ActionsMenuCell
              id={Number(row.original.id)}
              isGlobal={true}
              onArchiveSpecial={() => onArchiveSpecial(Number(row.original.id))}
              // Can't archive/duplicate a special that user doesn't have full access to (i.e. is associated with stores user doesn't manage)
              canArchive={!hasUneditableStores && !hasArchivedFilter}
              canDuplicate={!hasUneditableStores}
              onEditStores={() => onEditStores(row.original)}
            />
          )
        );
      },
    },
  ];
};
