import { useContext, useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { GlobalSpecialsModalContext } from '@jane/business-admin/providers';
import { Flex, Form, useFormContext } from '@jane/shared/reefer';

export const DiscountTypeAndDiscountAmountField = () => {
  const { getValues, trigger } = useFormContext();
  const discountTypeValue = getValues('discount_type');
  const specialTypeValue = getValues('special_type');
  const { isDisabled } = useContext(GlobalSpecialsModalContext);

  const specialTypeWatch = useWatch({ name: 'special_type' });

  useEffect(() => {
    // Need to run validation if the user selects Dollars or Target Price first
    // then enters in a value > 100 and switches to Percentage
    if (discountTypeValue === 'percent') trigger('discount_percent');
  }, [discountTypeValue]);

  const discountTypes = () => {
    const commonValues = [
      { label: 'Percentage (%)', value: 'percent' },
      { label: 'Dollars ($)', value: 'dollar_amount' },
    ];

    const targetPriceValue = { label: 'Target Price', value: 'target_price' };

    const specialType = specialTypeWatch || specialTypeValue;

    if (['product', 'bundle', 'bulk_pricing'].includes(specialType)) {
      return commonValues.concat([targetPriceValue]);
    }

    return commonValues;
  };

  return (
    <Flex gap={24} justifyContent="space-between" width="100%">
      <Form.SelectField
        name="discount_type"
        label="Discount type"
        options={discountTypes()}
        required
        width="50%"
        disabled={isDisabled}
      />

      <Form.NumberField
        name={`discount_${discountTypeValue}`}
        label="Discount amount"
        required
        width="50%"
        min={0}
        disabled={isDisabled}
        startUnit={
          ['dollar_amount', 'target_price'].includes(discountTypeValue)
            ? '$'
            : undefined
        }
        endUnit={discountTypeValue === 'percent' ? '%' : undefined}
        validate={(value: any) => {
          return discountTypeValue === 'percent' && value > 100
            ? 'Discount amount cannot exceed 100'
            : true;
        }}
      />
    </Flex>
  );
};
