import styled from '@emotion/styled';
import { useContext } from 'react';

import { Hide } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { CategoryCheckbox } from './CategoryCheckbox';
import type { CategoryOption } from './CategorySelectInputModal';
import { CategorySelectInputModalContext } from './CategorySelectInputModalProvider';

const CategoryDivider = styled.hr(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.colors.grays.light,
  height: 1,
  margin: '10px 0',
}));

export const StyledCategoryList = styled.ul<{
  query?: string;
  subList?: boolean;
  useDefaultModal?: boolean;
}>(({ theme, query, useDefaultModal, subList }) => ({
  maxHeight: useDefaultModal ? '360px' : 'auto',
  overflow: 'auto',
  position: 'relative',
  paddingLeft: subList ? '20px' : 'Opx',
  listStyle: 'none',
  '> li:first-of-type > div > div > div': {
    ...spacing({ pt: subList || query ? 0 : 12 }),
  },
  '> li:first-of-type > div > span': {
    top: '24px',
  },
  '> li:first-of-type > button': {
    ...spacing({ pt: 20 }),
  },
  '> li:last-of-type > button': {
    ...spacing({ pb: 20 }),
  },
  ...(subList
    ? {
        '&::before': {
          content: '""',
          position: 'absolute',
          left: '37px',
          top: '12px',
          bottom: '12px',
          width: '1px',
          backgroundColor: theme.colors.grays.light,
        },
      }
    : {}),
}));

export const CategoryList = ({
  query,
  filteredCategories,
  useDefaultModal,
  subList,
  parents = [],
}: {
  filteredCategories: CategoryOption[];
  isLastCategory?: boolean;
  parents?: Array<string>;
  query: string;
  subList: boolean;
  useDefaultModal?: boolean;
}) => {
  const { hideSubItems, expandAll } = useContext(
    CategorySelectInputModalContext
  );

  return (
    <>
      {filteredCategories.map(
        (filteredCategory: CategoryOption, index: number) => {
          return (
            <div key={`${filteredCategory.label}_category-list`}>
              <CategoryCheckbox
                category={filteredCategory}
                hasSubItems={
                  filteredCategory.subItems &&
                  filteredCategory.subItems.length > 0
                }
                isSubItem={subList}
                parents={parents}
              />
              {filteredCategory.subItems &&
                filteredCategory.subItems.length > 0 && (
                  <Hide
                    isHidden={
                      !hideSubItems[filteredCategory.value] && !expandAll
                    }
                  >
                    <StyledCategoryList
                      aria-label={`${filteredCategory?.label}: sub items`}
                      query={query}
                      subList
                      useDefaultModal={useDefaultModal}
                    >
                      <CategoryList
                        query={query}
                        isLastCategory={index === filteredCategories.length - 1}
                        filteredCategories={filteredCategory.subItems}
                        subList={true}
                        parents={[...parents, filteredCategory.value]}
                        useDefaultModal={useDefaultModal}
                      />
                    </StyledCategoryList>
                  </Hide>
                )}
              {index !== filteredCategories.length - 1 && !subList && (
                <CategoryDivider />
              )}
            </div>
          );
        }
      )}
    </>
  );
};
