import { useCheckout } from '@jane/shared-ecomm/providers';

import { useCheckoutPayments } from '../../CheckoutPayments/CheckoutPaymentsProvider';
import type { PaymentOptions } from './usePaymentOptions';
import { PaymentMethods } from './usePaymentOptions';

const TIP_PAYMENT_METHODS = [
  PaymentMethods.janePay,
  PaymentMethods.moneris,
  PaymentMethods.payfirma,
  PaymentMethods.stronghold,
] as PaymentOptions[];

export const useTipSettings = () => {
  const {
    cart: { reservation_mode },
    store: { delivery_tip_settings, tip_settings },
  } = useCheckout();

  const { selected } = useCheckoutPayments();

  const tipSettings =
    reservation_mode === 'delivery'
      ? delivery_tip_settings
      : tip_settings?.find(
          ({ enabled_for }) => enabled_for === reservation_mode
        );

  const tipAmounts = tipSettings?.custom_amounts.sort((a, b) => a - b) || [];

  const isPercentage = tipSettings?.variant === 'percentage';

  const storeTipOptions = tipAmounts.map((value) => ({
    label: isPercentage ? `${value}%` : `$${value}`,
    value: isPercentage ? Number(value) * 0.01 : Number(value),
  }));

  const tipOptions = storeTipOptions
    ? [...storeTipOptions, { label: 'Custom', value: 'custom' }]
    : [];

  const showTip =
    !!tipSettings && !!selected && TIP_PAYMENT_METHODS.includes(selected);

  return {
    showTip,
    tipOptions,
    tipSettings,
  };
};
