import styled from '@emotion/styled';
import { useDropzone } from 'react-dropzone';

import { Button, Flex, Loading, Typography } from '@jane/shared/reefer';

const UploadArea = styled.div(({ theme }) => ({
  height: 264,
  border: `2px dashed ${theme.colors.grays.light}`,
  borderRadius: theme.borderRadius.sm,
  width: '100%',
  backgroundColor: theme.colors.grays.white,
  cursor: 'pointer',
}));

const LoadingContainer = styled.div(() => ({
  div: {
    position: 'relative',
  },
}));

export interface FileUploadAreaProps {
  /** When true, dropzone allows multiple files to be uploaded at once */
  allowMultiple?: boolean;

  /** Label to describe drag and drop option */
  dragLabel?: string;

  /** When true, component renders loading symbol */
  isLoading: boolean;

  /** Label to describe upload from computer option */
  label?: string;

  /** Function called when file(s) are dropped in the dropzone */
  onDrop: (acceptedFiles: Array<File>) => void;
}
export const FileUploadArea = ({
  allowMultiple = false,
  onDrop,
  isLoading,
  label = 'Upload images from computer',
  dragLabel = 'Drag and drop images here',
}: FileUploadAreaProps) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    multiple: allowMultiple,
  });

  return (
    <UploadArea {...getRootProps()}>
      <Flex
        gap={12}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100%"
        ariaLabel="upload-image"
      >
        {isLoading ? (
          <LoadingContainer>
            <Loading color="black" data-testid="loading-file-upload" />
          </LoadingContainer>
        ) : (
          <>
            <Button variant="secondary" label={label} onClick={open} />
            <input data-testid="file-upload" {...getInputProps()} />
            <Typography color="grays-mid">or</Typography>
            <Typography color="grays-mid">{dragLabel}</Typography>
          </>
        )}
      </Flex>
    </UploadArea>
  );
};
