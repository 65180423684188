// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FLGdKJgYRQI6Ou8gghQl {\n  background-color: inherit;\n  position: relative;\n  top: 0;\n  z-index: var(--layers-overlay);\n}\n\n.JRX6hBbhjmo9Phzx7gjc {\n  position: sticky;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-table/src/lib/tableSection/tableSection.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,MAAM;EACN,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".tableSection {\n  background-color: inherit;\n  position: relative;\n  top: 0;\n  z-index: var(--layers-overlay);\n}\n\n.tableSection__fixed {\n  position: sticky;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableSection": "FLGdKJgYRQI6Ou8gghQl",
	"tableSection__fixed": "JRX6hBbhjmo9Phzx7gjc"
};
export default ___CSS_LOADER_EXPORT___;
