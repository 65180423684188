import styled from '@emotion/styled';
import type { ReactNode } from 'react';

import { Typography } from '@jane/shared/reefer';

import { FileDropProvider } from './FileDropContext';
import { useFileDrop } from './useFileDrop';

type FullScreenFileDropProps = {
  children: ReactNode;
  onFilesAdded: (fileList: FileList | null) => void;
};

/**
 * Wraps the given children with the FileDropProvider and injects the full
 * screen drop zone element.
 */
export const FullScreenFileDrop = ({
  children,
  onFilesAdded,
}: FullScreenFileDropProps) => {
  return (
    <FileDropProvider onFilesAdded={onFilesAdded}>
      {children}
      <FullScreenDropZone />
    </FileDropProvider>
  );
};

/**
 * Will render a full screen drop zone when the file drop zone is active.
 */
const FullScreenDropZone = () => {
  const { isActive } = useFileDrop();

  if (!isActive) return null;

  return (
    <FullScreenOverlay>
      <Typography as="p" variant="title-bold" color="grays-ultralight">
        Drop images here
      </Typography>
    </FullScreenOverlay>
  );
};

const FullScreenOverlay = styled.div(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderStyle: 'dashed',
  border: `6px dashed ${theme.colors.brand.purple.main}`,
  borderRadius: '1px',
  // zIndex so overlay shows over images
  zIndex: 'var(--layers-modal)',
}));
