import { useCallback, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  useDeleteStoreImage,
  useSaveStoreImages,
  useStoreImages,
} from '@jane/business-admin/data-access';
import { useDebouncedTrack } from '@jane/business-admin/hooks';
import type { CommunicationBanner } from '@jane/business-admin/types';
import {
  EventNames,
  FAMILIAR_MAX_IMAGE_SIZE,
  ModalNames,
  SettingNames,
  VALID_IMAGE_TYPES,
  normalizePath,
  track,
  validateImage,
} from '@jane/business-admin/util';
import {
  Flex,
  Form,
  Link,
  Modal,
  Typography,
  useFormContext,
} from '@jane/shared/reefer';
import { ImagePreview } from '@jane/shared/util';

import { ImageUploadArea } from './ImageUploadArea';

export const IMAGE_DISPLAY_ERROR_NAME = 'image-display-errors';

const MAX_MESSAGE_LENGTH = 300;

export const ImageDisplay = ({
  commsBannerData,
}: {
  commsBannerData?: CommunicationBanner;
}) => {
  const { id = '' } = useParams<'id'>();
  const { pathname } = useLocation();
  const {
    formState: { touchedFields },
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
  } = useFormContext();
  const { data: images } = useStoreImages(id);
  const existingImg =
    getValues('image') ||
    commsBannerData?.image ||
    !!images?.comms_banner_image;
  const [image, setImage] = useState<string | null>(
    existingImg?.file?.url || null
  );
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const trackTextChange = useDebouncedTrack(1000);

  const { mutateAsync: saveStoreImages } = useSaveStoreImages(id);
  const { mutateAsync: deleteStoreImages } = useDeleteStoreImage(
    id,
    'comms_banner_image'
  );
  const { watch } = useFormContext();
  const buttonText = watch('button_text');
  const message = watch('message');
  const messageLength = message?.length || 0;

  const imageUpload = useCallback(
    (file: File, setImage: (url: string) => void) => {
      const updateImage = async (file: File) => {
        clearErrors(IMAGE_DISPLAY_ERROR_NAME);

        if (!validateImage(file)) {
          setError(IMAGE_DISPLAY_ERROR_NAME, {
            type: 'validate',
            message: `Invalid image type or size! Image must be of type ${VALID_IMAGE_TYPES.map(
              (x) => x.replace(/image\//, '*.')
            )
              .join(', ')
              .replace(
                /, ([^,]*)$/,
                ' or $1'
              )} and smaller than ${FAMILIAR_MAX_IMAGE_SIZE}.`,
          });

          return;
        }

        const image = await ImagePreview.parse(file);

        saveStoreImages({ image_type: 'comms_banner_image', image: image.url });
        setImage(image.url);
        setValue('image', image.url, { shouldDirty: true, shouldTouch: true });
      };

      track({
        event: EventNames.EditedPhoto,
        action: 'upload',
        file_size: `${file.size} bytes`,
        file_type: file.type,
        settings_name: 'comms_banner_image',
        url: normalizePath(pathname, id),
      });

      updateImage(file);
    },
    [saveStoreImages]
  );

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={16}>
        <Flex flexDirection="column">
          <Typography variant="body-bold">Preview</Typography>
          {/* // TODO: uncomment once repositioning is implemented */}
          {/* <Typography>
            Click and drag to reposition photo within preview area.
          </Typography> */}
        </Flex>
        {image && (
          <div>
            <Link
              mr={24}
              onClick={() => {
                setImage(null);
                deleteStoreImages();
              }}
            >
              Delete image
            </Link>
            <Link
              onClick={() => {
                fileInputRef.current && fileInputRef.current.click();
              }}
            >
              Replace image
            </Link>
          </div>
        )}
      </Flex>
      <ImageUploadArea
        required
        image={image}
        setImage={setImage}
        commsBannerData={commsBannerData}
      />
      <Modal.ContentDivider />
      <Form.TextField
        label="Banner header"
        name="title"
        defaultValue={commsBannerData?.title}
        placeholder="Mix and match 8ths, build your ounce for 25% off!"
        onBlur={() => {
          trigger('header_text_color');
        }}
        onChange={() => {
          if (touchedFields['title']) trigger('header_text_color');

          trackTextChange({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerHeader,
            revert: false,
          });
        }}
      />
      <Typography variant="body-bold" mt={24} mb={4}>
        Message
      </Typography>
      <Typography mb={12}>
        ({messageLength}/{MAX_MESSAGE_LENGTH} characters used)
      </Typography>
      <Form.TextAreaField
        defaultValue={commsBannerData?.message}
        label="message"
        labelHidden
        name="message"
        onChange={() => {
          trackTextChange({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerMessage,
            revert: false,
          });
        }}
        maxLength={MAX_MESSAGE_LENGTH}
        rows={5}
      />
      <Form.TextField
        mt={24}
        label="Alternative text"
        name="alt_text"
        defaultValue={commsBannerData?.alt_text}
        helperText="Use this field to describe the function and/or appearance of your banner. This will be useful for SEO and visually impaired people that use screen readers to read aloud website details."
        placeholder="Dispensary deal on 8ths, add flower products for 25% off"
        onChange={(value) => {
          const revert = value === commsBannerData?.alt_text;
          trackTextChange({
            event: EventNames.ModifiedSetting,
            modal_name: ModalNames.CommsBanner,
            setting_name: SettingNames.CommsBannerAltText,
            revert,
          });
        }}
      />
      <Flex mt={24} gap={24}>
        <Form.TextField
          label="Button text"
          name="button_text"
          defaultValue={commsBannerData?.button_text}
          placeholder="Shop Bundles"
          onBlur={() => {
            trigger(['button_link', 'button_text_color', 'button_fill_color']);
          }}
          onChange={(value) => {
            const revert = value === commsBannerData?.button_text;

            if (touchedFields['button_text']) {
              trigger(['button_text_color', 'button_fill_color']);

              if (!value) {
                trigger('button_link');
              }
            }

            trackTextChange({
              event: EventNames.ModifiedSetting,
              modal_name: ModalNames.CommsBanner,
              setting_name: SettingNames.CommsBannerButtonText,
              revert,
            });
          }}
        />
        <Form.TextField
          label="Button destination"
          name="button_link"
          validate={(value) => {
            if (buttonText && !value) {
              return 'This field is required when button text is present';
            }

            return true;
          }}
          defaultValue={commsBannerData?.button_link}
          placeholder="https://www.iheartjane.com"
          onChange={(value) => {
            const revert = value === commsBannerData?.button_link;
            trackTextChange({
              event: EventNames.ModifiedSetting,
              modal_name: ModalNames.CommsBanner,
              setting_name: SettingNames.CommsBannerButtonLink,
              revert,
            });
          }}
        />
      </Flex>
      <label style={{ display: 'none' }}>
        Image upload
        <input
          ref={fileInputRef}
          type="file"
          multiple
          style={{ display: 'none' }}
          accept={'image/*'}
          onChange={(event) => {
            event.preventDefault();
            const files = event.currentTarget.files;
            if (files && files[0]) {
              imageUpload(files[0], setImage);
              track({
                event: EventNames.ModifiedSetting,
                modal_name: ModalNames.CommsBanner,
                setting_name: SettingNames.CommsBannerImageFile,
                revert: false,
              });
            }
          }}
        />
      </label>
    </>
  );
};
