import { useFormContext, useWatch } from 'react-hook-form';

import { filterPrepaymentProviders } from '@jane/shared-ecomm/components';
import type { PrepaymentProviders } from '@jane/shared-ecomm/providers';
import { PrepaymentMethods, useCheckout } from '@jane/shared-ecomm/providers';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { titleCase } from '@jane/shared/util';

export const InPersonPaymentMethods = {
  cash: 'cash',
  credit: 'credit',
  debit: 'debit',
} as const;

export const PaymentMethods = {
  ...InPersonPaymentMethods,
  ...PrepaymentMethods,
};

export type InPersonPaymentOptions =
  typeof InPersonPaymentMethods[keyof typeof InPersonPaymentMethods];

export type PaymentOptions = PrepaymentProviders | InPersonPaymentOptions;

const ACTIVE_PRE_PAYMENT_OPTIONS = [
  'aeropay',
  // 'aeropay_white_label',
  'canpay',
  // 'hypur',
  'jane_pay',
  'moneris',
  'payfirma',
  'paytender',
  'square',
  'stronghold',
];

export const usePaymentOptions = () => {
  const {
    prepaymentProviders,
    store,
    appInfo: { disablePrepayment },
    cart: { reservation_mode },
  } = useCheckout();
  const { control } = useFormContext();

  const janePayAndAeropayReservationModes = useFlag(
    FLAGS.consumerScJanePayByReservationMode
  );
  const pickupMode = useWatch({ name: 'pickupOptions.pickupMode', control });

  const filteredPrepaymentProviders = disablePrepayment
    ? []
    : prepaymentProviders.filter((option) =>
        ACTIVE_PRE_PAYMENT_OPTIONS.includes(option)
      );

  const allowedPrePaymentOptions = janePayAndAeropayReservationModes
    ? filterPrepaymentProviders({
        prepaymentOptions: filteredPrepaymentProviders,
        janePayIntegration: store.jane_pay_integration,
        aeropayIntegration: store.aeropay_integration,
        reservationMode: reservation_mode,
        pickupMode,
      })
    : filteredPrepaymentProviders;

  const hasJanePay = allowedPrePaymentOptions.includes(PaymentMethods.janePay);

  const sortedPrePrepaymentOptions = [
    ...(hasJanePay ? [PaymentMethods.janePay] : []),
    ...allowedPrePaymentOptions.filter(
      (option) => option !== PaymentMethods.janePay
    ),
  ];

  const inPersonPaymentOptions =
    reservation_mode === 'delivery'
      ? store.delivery_payment_options
      : reservation_mode === 'pickup' && pickupMode === 'pickup'
      ? store.pickup_payment_options
      : store.curbside_payment_options;

  const isCustom = (option: string) =>
    !([PaymentMethods.credit, PaymentMethods.debit] as string[]).includes(
      option.toLowerCase()
    );

  // We're sorting it as debit/credit first, then custom options, then cash.
  const sortCustomPayments = (a: string, b: string) => {
    if (a.toLowerCase().includes(PaymentMethods.cash)) return 1;
    if (b.toLowerCase().includes(PaymentMethods.cash)) return -1;

    const isCustomA = isCustom(a) ? -1 : 1;
    const isCustomB = isCustom(b) ? -1 : 1;

    return isCustomB - isCustomA;
  };

  const sortedPaymentOptions = inPersonPaymentOptions?.length
    ? inPersonPaymentOptions.sort(sortCustomPayments)
    : [];

  const hasPrepaymentValue = sortedPaymentOptions.some((option) =>
    ACTIVE_PRE_PAYMENT_OPTIONS.includes(option)
  );

  // ie. if a dispensary has "aeropay" as an in-store option, we change it to "Aeropay" so it isn't confused as a prepayment.
  const dedupedPaymentOptions = hasPrepaymentValue
    ? sortedPaymentOptions.map((option) =>
        ACTIVE_PRE_PAYMENT_OPTIONS.includes(option) ? titleCase(option) : option
      )
    : sortedPaymentOptions;

  return {
    paymentOptions: dedupedPaymentOptions as InPersonPaymentOptions[],
    prepaymentOptions: sortedPrePrepaymentOptions as PrepaymentProviders[],
  };
};
