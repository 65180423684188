import { useContext } from 'react';

import { GlobalSpecialsModalContext } from '@jane/business-admin/providers';
import type { SpecialTypeProps } from '@jane/business-admin/types';
import { Flex, Form } from '@jane/shared/reefer';

export const Bundle = ({ name }: SpecialTypeProps['bundle']) => {
  const { isDisabled } = useContext(GlobalSpecialsModalContext);

  return (
    <Flex
      justifyContent="space-between"
      width="100%"
      gap={24}
      flexDirection="column"
    >
      <Flex width="100%" gap={24} mb={24}>
        <Form.NumberField
          width={'100%'}
          name="display.bundle.threshold_number_of_items_in_cart"
          label={'Minimum products requirement'}
          min={1}
          disabled={isDisabled}
          required
        />
        <Form.NumberField
          width={'100%'}
          name="display.bundle.max_number_of_discounted_products"
          label={'Maximum products discounted'}
          min={1}
          disabled={isDisabled}
          required
        />
      </Flex>
      <Flex width="100%" gap={24} mb={24}>
        <Form.NumberField
          width={'100%'}
          name="display.bundle.max_applications_per_cart"
          label={'Maximum uses per order'}
          disabled={isDisabled}
        />
      </Flex>
    </Flex>
  );
};
